﻿.c-text-search {

	@include critical {
		display: flex;
		flex: 1;
		position: relative;
	}

}

.c-text-search__button {

	@include critical {
		position: absolute;
		top: 0;
		right: rem(10px); 
		height: 100%;
		padding: rem(10px);
		margin: 0;
		border: none;
		background-color: transparent;
		display: none;
		cursor: pointer;

		&.input-has-value {
			display: block;
		}
	}
}