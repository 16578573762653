.c-sponsor {

	@include critical {
		border: 2px solid #D3DAE2;
		display: inline-flex;
		text-decoration: none;
		padding: rem(10px);
		align-items: center;
		text-align: left;
		border-radius: 3px;

		@include mq(desktop) {
			padding: rem(20px);
		}
	}
}

.c-sponsor__text {

	@include critical {
		display: block;
		padding: 0 rem(20px) 0 0;
		max-width: rem(80px);
		font-size: rem(14px);
		line-height: rem(18px);

		@include mq(desktop) {
			padding: 0 rem(30px) 0 0;
			max-width: rem(90px);
		}
	}
}

.c-sponsor__image {

	@include critical {
		display: block;
		max-width: rem(125px);

		@include mq(desktop) {
			max-width: rem(190px);
		}
	}
}

a.c-sponsor {

	@include defer {
		transition: box-shadow 0.5s ease;

		@include hover {
			box-shadow: 0px 0px 10px 5px rgba(#D3DAE2, 0.35);
		}
	}
}

.c-sponsor--small {

	.c-sponsor__text {

		@include critical {
			font-size: rem(13px);
			line-height: rem(17px);
		}
	}
}
