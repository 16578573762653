﻿.c-share {

	@include critical {

	}
}

.c-share__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.c-share__item {

	@include critical {

	}
}

.c-share__link {

	@include critical {
		display: block;
		width: 45px;
		height: 45px;
		position: relative;
		color: color(yellow);
	}

	@include defer {
		transition: color 0.25s ease;

		@include hover {
			color: color(white);
		}
	}
}

.c-share__icon {

	@include critical {
		display: block;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -10px 0 0 -10px;
	}
}