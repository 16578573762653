.c-button {

	@include critical {
		cursor: pointer;
		display: inline-block;
		padding: rem(15px) rem(50px) rem(14px);
		margin: 0;
		border-radius: 0;
		text-decoration: none;
		text-align: center;
		border: 0;
		@include font-bold();
		font-size: rem(14px);
		line-height: normal;
		vertical-align: top;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: color(yellow);
		color: color(navy);
		border: 2px solid color(yellow);
		text-transform: uppercase;
		border-radius: 4px;
		min-height: 50px;
	}

	@include defer {
		transition: background 0.25s ease, color 0.25s ease, border 0.25s ease;

		@include hover {
			background: color(white);
			color: color(navy);
			border-color: (navy);
		}
	}
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {

	@include defer {
		border: 0;
		padding: 0;
		margin: 0;
	}
}

.c-button--secondary {

	@include critical {
		padding-left: rem(35px);
		padding-right: rem(35px);
		background: color(navy);
		color: color(yellow);
		border: 2px solid color(navy);
	}
}

.c-button--small {

	@include critical {
		min-height: rem(40px);
		padding: rem(11px) rem(25px) rem(9px);
		font-size: rem(12px);
	}
}

.c-button--tight {

	@include critical {
		padding-left: rem(15px);
		padding-right: rem(15px);
	}
}

.c-button--small-mobile {

	@include critical {

		@include mq(0, desktop) {
			min-height: rem(40px);
			padding: rem(10px) rem(25px) rem(9px);
			font-size: rem(12px);
		}
	}
}

.c-button--play {

	@include critical {
		position: relative;
		padding-left: rem(30px);
		padding-right: rem(75px);

		&.c-button--small {
			padding-left: rem(25px);
			padding-right: rem(60px);
		}

		&:before {
			content: '';
			display: block;
			width: rem(30px);
			height: rem(30px);
			border: 1px solid color(navy);
			border-radius: 100%;
			position: absolute;
			top: 50%;
			right: rem(30px);
			margin: -15px 0 0 0;
		}

		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 6px solid color(navy);
			position: absolute;
			top: 50%;
			right: rem(45px);
			margin: -5px -3px 0 0;
		}

		&.c-button--small:before {
			width: rem(22px);
			height: rem(22px);
			margin: -11px 0 0 0;
			right: rem(25px);
		}

		&.c-button--small:after {
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-left: 5px solid color(navy);
			right: rem(35px);
			margin: -4px -2px 0 0;
		}
	}
}

.c-button-group {

	@include critical {
		display: flex;

		.c-button {
			margin-left: 0;
			flex-grow: 1
		}
	}
}

.c-button--play-collapse {

	@include critical {

		@include mq(0, desktop) {
			padding: 0;
			width: rem(40px);
			height: rem(40px);
			display: block;
			border: 0;
			border-radius: 100%;
			min-height: rem(40px);

			&:before {
				display: none;
			}

			&:after {
				right: auto;
				left: 50%;
				margin: -6px 0 0 -4px;
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-left: 8px solid color(navy);
			}

			.c-button__text {
				@include hide();
			}
		}
	}
}

.c-button--line {
	@include critical {
		border-color: color(navy);
		border-radius: 0;

		&.is-active {
			background-color: color(navy);
			color: color(yellow);
		}
	}
}

.c-button--with-icon {
	@include critical {
		display: inline-flex;
		align-items: center;
	}
}

.c-button__icon {
	@include critical {
		margin-left: rem(5px);
	}
}

.c-button--with-positioned-icon {

	.c-button__inner {

		@include critical {
			position: relative;
			padding: 0 29px 0 0;
			display: inline-block;

			.c-button__icon {
				margin: 0;
				position: absolute;
				right: 0;
				top: 50%;
				width: 24px;
				height: 24px;
				margin: -13px 0 0 0;
			}
		}
	}
}

.c-button-group {

	@include critical {
		display: flex;

		.c-button {
			margin-left: 0;
			flex-grow: 1
		}
	}
}

.c-button-group--full-split {

	@include critical {
		width: 100%;
		justify-content: space-between;

		.c-button {
			width: 50%;
			flex-grow: 0;
		}
	}
}

.c-button--outline {

	@include critical {
		background: transparent;
		color: color(yellow);
	}

	@include defer {

		@include hover {
			background: color(yellow);
			color: color(navy);
			border-color: color(yellow);
		}
	}
}

.c-button--white-widest {

	&.c-button--outline {

		@include critical {

			@include mq(widest) {
				color: color(white);
				border-color: color(white);
			}
		}

		@include defer {

			@include hover {

				@include mq(widest) {
					background: color(white);
					color: color(navy);
					border-color: color(white);
				}
			}
		}
	}
}

.c-button--white {

	&.c-button--outline {

		@include critical {
			color: color(white);
			border-color: color(white);
		}

		@include defer {

			@include hover {
				background: color(white);
				color: color(navy);
				border-color: color(white);
			}
		}
	}
}

.c-button--no-border {

	@include critical {
		border: 0;
		padding-top: rem(17px);
		padding-bottom: rem(16px);
	}
}

.c-button--large-desktop {

	@include critical {

		@include mq(desktop) {
			padding: rem(26px) rem(50px) rem(25px);
			font-size: rem(18px);

			&.c-button--tight {
				padding-left: rem(30px);
				padding-right: rem(30px);
			}

			&.c-button--with-positioned-icon {

				.c-button__inner {
					padding-right: 60px;
				}

				.c-button__icon {
					width: 46px;
					height: 46px;
					margin: -24px 0 0 0;
				}
			}
		}
	}
}
