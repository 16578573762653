﻿.c-tabs {

	@include critical {
		margin: 0;
		padding: 0;

		.js & {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@include mq(desktop) {
				justify-content: flex-start;
			}
		}
	}
}

.c-tabs__title {

	@include critical {
		margin: 0;
		padding: 0;
	}
}

.c-tabs__button {

	@include critical {
		margin: 0;
		padding: 0 rem(10px) rem(12px);
		border-radius: 0;
		border: 0;
		@include font-default();
		font-size: rem(14px);
		line-height: rem(14px);
		@include appearance-none();
		background: none;
		color: color(navy);

		.no-js & {
			padding: rem(30px) 0;
		}

		@include mq(desktop) {
			font-size: rem(20px);
			line-height: rem(20px);
			padding: 0 rem(35px) rem(18px);
		}
	}

	@include defer {
		outline: none;
		@include focus();

		.js & {
			cursor: pointer;
			border-bottom: 3px solid transparent;
			position: relative;
			z-index: 1; // lift above content
		}

		.js &.is-active {
			text-shadow: 1px 0 0 color(navy);
			border-color: color(navy);
		}
	}
}

.c-tabs__content {

	@include critical {
		margin: 0;
		padding: 0;

		.js & {
			display: none;
		}

		.js &.is-active {
			display: block;
			width: 100%;
			order: 99;
			flex-basis: 100%;
			border-top: 3px solid #BED4EA;
			position: relative;
			top: -3px; // pull up under button border
			margin-bottom: -3px;
		}
	}
}

.c-tabs__content--pad {

	@include critical {
		padding: rem(30px) rem(10px) 0;

		@include mq(desktop) {
			padding: rem(50px) rem(35px) 0;
		}
	}
}

.c-tabs__content--pad-top {

	@include critical {
		padding: rem(30px) 0 0 0;

		@include mq(desktop) {
			padding: rem(50px) 0 0 0;
		}
	}
}

.c-tabs__content__wrapper {

	@include critical {

		> * {

			&:not(:first-child) {
				margin: spacing(medium) 0 0 0;

				@include mq(desktop) {
					margin: 80px 0 0 0;
				}
			}
		}
	}
}

.c-tabs--solid-background {

	@include critical {

		.c-tabs__content {
			background: color(white);
		}

		.c-tabs__content--pad {
			padding-bottom: rem(30px);

			@include mq(desktop) {
				padding-bottom: rem(50px);
			}
		}
	}
}

.c-tabs--accordion {

	@include critical {


		@include mq(0, wide) {

			.js & {
				display: block;
				border-bottom: 1px solid color(navy);

				.c-tabs__title {
					border-top: 1px solid color(navy);
				}

				.c-tabs__button {
					display: block;
					border: 0;
					width: 100%;
					padding: rem(18px) rem(55px) rem(18px) rem(20px);
					text-align: left;
					@include font-default();
					font-size: rem(14px);
					line-height: rem(14px);
					position: relative;

					&:before {
						content: '';
						display: block;
						width: 12px;
						height: 12px;
						border: 2px solid color(navy);
						border-width: 0 2px 2px 0;
						position: absolute;
						top: 50%;
						right: 21px;
						transform: rotate(45deg);
						margin: -10px 0 0 0;
					}

					&.is-active {

						&:before {
							transform: rotate(-135deg);
							margin: -4px 0 0 0;
						}
					}
				}

				.c-tabs__content {
					padding: 0;
					transition: height 0.5s ease;
					overflow: hidden;
					height: 0;
					display: block;

					&.is-active {
						border: 0;
						position: static;
						margin: 0;
						top: auto;
						height: auto;
					}
				}

				.c-tabs__content__inner {
					padding: 0 0 rem(40px) 0;
				}
			}
		}
	}
}