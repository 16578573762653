.u-visuallyhidden {

	@include critical {
		@include hide();
	}
}

.u-constrain {

	@include critical {
		@include constrain();
	}
}

.u-constrain-narrow {

	@include critical {
		@include constrain($width: width(contentNarrow));
	}
}

.u-constrain-flush {

	@include critical {
		@include constrain($width: width(content), $padding: 0);
	}
}

.u-constrain-wide-flush {

	@include critical {
		@include constrain($width: width(contentWide), $padding: 0);
	}
}

.u-constrain-until-wide {

	@include critical {

		@include mq(0, wide) {
			@include constrain();
		}
	}
}

.u-constrain-medium {

	@include critical {
		@include constrain($width: width(contentMedium));
	}
}

.u-constrain-medium-flush {

	@include critical {
		@include constrain($width: width(contentMedium), $padding: 0);
	}
}

.u-constrain-medium-flush-after-large-desktop {

	@include critical {
		@include constrain($width: width(contentMedium));

		@include mq(largeDesktop) {
			@include constrain($width: width(contentMedium), $padding: 0);
		}
	}
}

.u-center {

	@include critical {
		text-align: center;
	}
}

.u-center--small {

	@include critical {
		@include mq(0, wide) {
			text-align: center;
		}
	}
}

.u-spacer {

	@include critical {
		margin: 0 0 spacing(default) 0;
	}
}

.u-spacer--large {

	@include critical {
		margin: 0 0 spacing(large) 0;
	}
}

.u-spacer--small {

	@include critical {
		margin: 0 0 spacing(small) 0;
	}
}

.u-spacer--tiny {

	@include critical {
		margin: 0 0 spacing(tiny) 0;
	}
}

[aria-hidden="true"] {
	@include critical {
		display: none;
	}
}

.u-constrain-narrow {

	@include critical {
		@include constrain($width: width(contentNarrow));
	}
}

.u-constrain-small {

	@include critical {
		@include constrain();

		@include mq(wide) {
			@include constrain($width: width(contentSmall));
		}
	}
}

.u-block-link {
	@include critical {
		display: block;
		text-decoration: none;
	}
}

.u-hide-wider-and-less {

	@include critical {

		@include mq(0, wider) {
			display: none !important;
		}
	}
}

.u-hide-wider-and-greater {

	@include critical {

		@include mq(wider) {
			display: none !important;
		}
	}
}
