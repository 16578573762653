$subNavInSidebarBreakpoint: 1380px;

.c-sub-nav__grid {
	@include critical {
		@include mq($subNavInSidebarBreakpoint) {
			display: flex;
		}
	}
}

.c-sub-nav__grid-content {
	@include critical {
		flex: 1;

		@include mq($subNavInSidebarBreakpoint) {
			.c-section__inner {
				margin: 0;
			}
		}
	}
}

.c-sub-nav-container--has-banner + .c-sub-nav__grid-content {
	@include critical {
		@include mq($subNavInSidebarBreakpoint, width(content)) {
			.c-section__inner {
				margin: 0 auto;
			}
		}
	}
}

.c-sub-nav-container {

	@include critical {

		@include mq(0, $subNavInSidebarBreakpoint) {
			@include constrain($width: width(contentSmall));
		}

		@include mq(wide, $subNavInSidebarBreakpoint) {
			margin-bottom: -40px;
		}

		@include mq($subNavInSidebarBreakpoint) {
			position: relative;
			width: 18%;
		}

		@include mq(widest) {
			width: 300px;
		}

		@include mq(1750px) {
			margin-left: -50px;
			width: 360px;
		}
	}
}

.c-sub-nav-container--has-banner {
	@include critical {

		@include mq($subNavInSidebarBreakpoint, width(content)) {
			position: absolute;
		}
	}
}

.c-sub-nav__mobile-toggle {
	@include critical {
		@include mq(0, $subNavInSidebarBreakpoint) {
			width: 100%;
			margin-top: rem(40px);
			padding: rem(13px) rem(25px);

			.c-button__icon {
				margin-left: auto;
			}

			&[aria-expanded="true"] {
				.c-sub-nav__mobile-toggle-open-icon {
					display: none;
				}
			}

			&[aria-expanded="false"] {
				.c-sub-nav__mobile-toggle-close-icon {
					display: none;
				}
			}
		}

		@include mq($subNavInSidebarBreakpoint) {
			display: none;
		}
	}
}

.c-sub-nav__nav-container {
	@include mq(0, $subNavInSidebarBreakpoint) {
		max-height: 0;
		overflow: hidden;
		transition: max-height 300ms;

		&.is-open {
			max-height: 500px; /* Overridden by JavaScript */
		}
	}
}

.c-sub-nav {
	@include critical {

		padding: rem(40px);
		background-color: color(yellow);

		@include mq($subNavInSidebarBreakpoint) {
			padding: rem(40px) rem(20px) rem(40px) rem(30px);
		}

		@include mq(widest) {
			padding: rem(40px) rem(40px) rem(40px) rem(60px);
		}

		@include mq(1750px) {
			padding: rem(60px) rem(60px) rem(60px) rem(90px);
		}
	}
}

.c-sub-nav--no-banner {
	@include critical {
		@include mq($subNavInSidebarBreakpoint) {
			padding-top: rem(110px);
		}
	}
}

.c-sub-nav-container--has-banner {
	@include critical {
		@include mq($subNavInSidebarBreakpoint) {
			margin-top: -180px;
		}
	}
}

.c-sub-nav-container--no-banner {
	@include critical {
		@include mq($subNavInSidebarBreakpoint) {
			margin-bottom: 40px;
		}
	}
}

.c-sub-nav__title {
	@include critical {
		text-transform: uppercase;
		font-size: rem(18px);
		@include font-bold();
		margin: 0 0 rem(30px) 0;

		@include mq(widest) {
			font-size: rem(19px);
			margin: 0 0 rem(40px) 0;
		}
	}
}

.c-sub-nav__list {
	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.c-sub-nav__list--sub {
	@include critical {
		margin: 0 0 0 rem(20px);
	}
}

.c-sub-nav__link {
	@include critical {
		@include font-medium();
		font-size: rem(16px);
		text-decoration: none;
	}

	@include defer {
		@include hover {
			text-decoration: underline;
		}
	}
}

.c-sub-nav__link--active {
	@include defer {
		position: relative;

		&:before {
			content: '—';
			position: absolute;
			left: -20px;
			height: 2px;
			color: #444;
		}
	}
}

.c-sub-nav__list-item:not(:last-child) {
	@include critical {
		margin-bottom: rem(20px);
	}
}
