﻿.c-medal {

	@include critical {
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 100%;
		line-height: rem(30px);
		text-align: center;
		color: color(black);

		@include mq(wide) {
			width: 40px;
			height: 40px;
			line-height: rem(40px);
		}
	}
}

.c-medal--bronze {

	@include critical {
		background: color(bronze);
	}
}

.c-medal--silver {

	@include critical {
		background: color(silver);
	}
}

.c-medal--gold {

	@include critical {
		background: color(gold);
	}
}