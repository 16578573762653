.c-match-banner {

	@include critical {

	}
}

.c-match-banner__inner {

	@include critical {
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			@include gradient();
		}
	}
}

.c-match-banner__vs {

	@include critical {
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		color: color(white);
		opacity: 0.1;
		z-index: 2;
	}
}

.c-match-banner__image {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.c-match-banner__content {

	@include critical {
		position: relative;
		z-index: 3;
		padding: spacing(medium) 0 spacing(default);
		color: color(white);
		text-align: center;

		@include mq(desktop) {
			padding: spacing(large) 0;
		}

		@include mq(wide) {
			padding: rem(135px) 0 rem(150px);
		}
	}
}

.c-match-banner__content__inner {

	@include critical {
		display: flex;
		justify-content: space-between;
	}
}

.c-match-banner__team {

	@include critical {
		font-size: rem(14px);
		line-height: rem(20px);
		text-transform: uppercase;
		color: color(white);
		@include font-bold();
		margin: rem(10px) 0 0 0;
		overflow-wrap: break-word;

		@include mq(desktop) {
			font-size: rem(30px);
			line-height: rem(36px);
			margin: spacing(small) 0 0 0;
		}

		@include mq(wide) {
			font-size: rem(40px);
			line-height: rem(46px);
			margin: spacing(default) 0 0 0;
		}

		@include mq(widest) {
			font-size: rem(50px);
			line-height: rem(56px);
		}

		@include mq(0, desktop) {

			&:before {
				content: attr(data-abbr);
			}
		}

		.c-match-banner--force-abbreviations &:before {
			content: attr(data-abbr);
		}
	}
}

.c-match-banner__team__inner {

	@include critical {

		@include mq(0, desktop) {
			@include hide();
		}

		.c-match-banner--force-abbreviations & {
			@include hide();
		}
	}
}

.c-match-banner__logo {

	@include critical {
		margin: 0 auto;
		max-width: 220px;
	}
}

.c-match-banner__logo__inner {

	@include critical {
		@include ratio(1,1);
		position: relative;
		background-size: contain;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		html:not(.no-objectfit) & {
			background-image: none !important;
		}
	}
}

.c-match-banner__logo__image {

	@include critical {
		display: block;
		object-fit: contain;
		position: absolute;
		top: 5px;
		left: 5px;
		width: calc(100% - 10px);
		height: calc(100% - 10px);

		html.no-objectfit & {
			display: none;
		}

		@include mq(desktop) {
			top: 20px;
			left: 20px;
			width: calc(100% - 40px);
			height: calc(100% - 40px);
		}
		}
	}

	.c-match-banner__score {

		@include critical {
			font-size: rem(20px);
			line-height: rem(20px);
			text-transform: uppercase;
			color: color(white);
			@include font-black();
			margin: 0;

			@include mq(desktop) {
				font-size: rem(50px);
				line-height: rem(50px);
			}

			@include mq(wide) {
				font-size: rem(80px);
				line-height: rem(80px);
			}

			&:not(:last-child) {
				margin: 0 0 spacing(small);

				@include mq(desktop) {
					margin: 0 0 rem(40px);
				}

				@include mq(wide) {
					margin: 0 0 rem(70px);
				}
			}
		}
	}

	.c-match-banner__competition {

		@include critical {
			margin: 0;

			@include mq(0, desktop) {
				font-size: rem(14px);
				line-height: rem(20px);
			}
		}
	}

	.c-match-banner__venue {

		@include critical {
			font-size: rem(18px);
			line-height: rem(29px);
			margin: 0;

			@include mq(0, desktop) {
				font-size: rem(14px);
				line-height: rem(20px);
			}
		}
	}

	.c-match-banner__datetime {

		@include critical {
			margin: 0;
			color: color(yellow);

			@include mq(0, desktop) {
				font-size: rem(14px);
				line-height: rem(20px);
			}

			&:not(:last-child) {
				margin: 0 0 rem(10px);

				@include mq(desktop) {
					margin: 0 0 rem(30px);
				}
			}
		}
	}

	.c-match-banner__datetime__item {

		@include critical {

			@include mq(0, desktop) {
				display: block;
			}
		}
	}

	.c-match-banner__action {

		@include critical {
			margin: rem(30px) 0 0 0;

			@include mq(desktop) {
				margin: rem(50px) 0 0 0;
			}

			@include mq(0, tablet) {
				margin-left: -#{rem(30px)};
				margin-right: -#{rem(30px)};
			}
		}
	}

	.c-match-banner__action__button {
		margin: 0 0 rem(20px) 0;

		@include mq(desktop) {
			margin: 0 0 rem(50px) 0;
		}
	}

	.c-match-banner__content__section--copy {

		@include critical {
			padding: 0 spacing(small);

			@include mq(desktop) {
				padding: 0 spacing(default);
			}
		}
	}

	.c-match-banner__content__section--logo {

		@include critical {
			width: 65px;

			@include mq(desktop) {
				width: 160px;
			}

			@include mq(wide) {
				width: 260px;
			}
		}
	}

	.c-match-banner__countdown {

		@include critical {
			display: none;

			.js & {
				margin: 0 0 rem(30px) 0;
				display: block;
				min-height: 98px; // stop layout shift
				@include mq(0, desktop) {
					position: absolute;
					top: spacing(medium);
					left: 0;
					right: 0;
					margin: 0;
				}

				@include mq(desktop) {
					margin: 0 0 rem(45px) 0;
					min-height: 103px; // stop layout shift
				}

				@include mq(wide) {
					min-height: 148px; // stop layout shift
				}
			}
		}
	}

	.c-match-banner__countdown__title,
	.c-match-banner__fulltime-title,
	.c-match-banner__tbc-title {

		@include critical {
			font-size: rem(19px);
			line-height: rem(23px);
			text-transform: uppercase;
			margin: 0 0 rem(10px) 0;
			@include font-bold();

			@include mq(desktop) {
				font-size: rem(23px);
				line-height: rem(27px);
				margin: 0 0 rem(15px) 0;
			}
		}
	}

	.c-match-banner__tbc-title {
		@include mq(desktop) {
			margin: 0 0 rem(30px) 0;
		}
	}

	.c-match-banner--with-countdown-clock {

		@include critical {

			.c-match-banner__content {

				.js & {

					@include mq(0, desktop) {
						padding-top: rem(spacing(medium) + 98px + spacing(default)); // .c-match-banner__content padding + countdown height + extra spacing
					}

					@include mq(desktop) {
						padding-top: rem(60px);
						padding-bottom: rem(70px);
					}
				}
			}

			.c-match-banner__content__section--logo {

				.js & {

					@include mq(desktop) {
						padding-top: rem(70px);
					}
				}
			}
		}
	}

	.c-match-banner--with-video {

		@include critical {

			.c-match-banner__content {
				padding: 0;
			}

			.c-match-banner__content__inner {
				display: block;
				@include constrain(1195px, 0);
			}
		}
	}

.c-match-banner--empty {

	@include critical {
		height: 250px;

		@include mq(desktop) {
			height: 400px;
		}

		.c-match-banner__inner {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.c-match-banner__content {
			padding: 0;
		}

		.c-match-banner__action {
			margin: 0;
		}
	}
}
