﻿.c-simple-banner {

	@include critical {
		background: color(navy);
		color: color(white);

		&--slim {

			.c-simple-banner__copy {
				height: auto;
			}
		}
	}
}

.c-simple-banner__inner {

	@include critical {
		position: relative;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include gradient();
		}

		html:not(.no-objectfit) & {
			background-image: none !important;
		}
	}
}

.c-simple-banner__img {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		html.no-objectfit & {
			display: none;
		}
	}
}

.c-simple-banner__copy {

	@include critical {
		min-height: 175px;
		position: relative;
		z-index: 1;
		display: flex;
		align-items: flex-end;

		@include mq(desktop) {
			height: 355px; // min-height would be better but IE11
		}

		@include mq(wide) {
			height: 555px; // min-height would be better but IE11
		}
	}
}

.c-simple-banner__copy__inner {

	@include critical {
		padding: spacing(default) 0;

		@include mq(desktop) {
			padding: spacing(medium) 0;
		}

		@include mq(wide) {
			padding: rem(80px) 0;
		}
	}
}

.c-simple-banner__subtitle {

	@include critical {
		margin: spacing(small) 0 0 0;

		@include mq(desktop) {
			margin: spacing(default) 0 0 0;
		}
	}
}

.c-simple-banner--center {

	@include critical {

		.c-simple-banner__copy {
			text-align: center;
			justify-content: center;

			.t-alpha--emphasise {

				&:after {
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
}

.c-simple-banner__form {
	@include critical {
		.c-text-input,
		.autocomplete__input {
			background-color: #FFF;
		}
	}
}

.c-simple-banner__action {

	@include critical {
		margin: spacing(default) 0 0 0;
	}
}
