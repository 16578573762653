﻿.c-medal-table-header {

	@include critical {
		margin: 0 0 spacing(default) 0;
		display: flex;

		@include mq(desktop) {
			margin: 0 0 spacing(medium) 0;
			align-items: center;
		}
	}
}

.c-medal-table-header__item {

	@include critical {
		
		&:last-child {
			margin-left: auto;
		}
	}
}
