﻿.c-league-table {

	@include critical {
		border-collapse: collapse;
		width: 100%;

		td,
		th {
			border-bottom: 1px solid color(catskill-white);
			vertical-align: middle;
			padding: rem(15px);
			text-align: left;
			text-transform: uppercase;

			&:nth-child(2) {
				border-right: 1px solid color(catskill-white);
			}

			&:last-child {
				border-left: 1px solid color(catskill-white);
			}
		}

		tr {

			&:last-child {

				th,
				td {
					border-bottom: 0;
				}
			}
		}
	}
}

.c-league-table__title {

	@include critical {
		font-size: rem(15px);
		line-height: rem(19px);
		color: color(navy);
		text-transform: uppercase;
		@include font-bold();
		margin: 0;

		@include mq(desktop) {
			font-size: rem(16px);
			line-height: rem(20px);
		}
	}
}

.c-league-table__title__team {

	@include critical {

		@include mq(0, desktop) {

			&:before {
				content: attr(data-abbr);
			}
		}
	}
}


.c-league-table__title__team__inner {

	@include critical {

		@include mq(0, desktop) {
			@include hide();
		}
	}
}

abbr.c-league-table__abbr,
abbr.c-league-table__title__abbr {

	@include critical {
		text-decoration: none;
	}
}

.c-league-table__image {

	@include critical {
		display: block;
		width: 40px;
		height: auto;
	}
}

.c-league-table:not(.c-league-table--summary) {

	@include critical {

		td,
		th {

			@include mq(0, desktop) {

				&:first-child {
					display: none;
				}

				&:nth-child(n+7):nth-child(-n+9) {
					display: none;
				}
			}
		}
	}
}