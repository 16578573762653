﻿@include critical {

	.c-pagination {
		text-align: center;
		white-space: nowrap;
	}

	.c-pagination__list {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.c-pagination__item {
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
		@include font-medium();
	}

	.c-pagination__item--active {
		@include font-bold();
	}

	.c-pagination__item:not(:last-child) {
		margin: 0 rem(5px) 0 0;

		@include mq(0, desktop) {
			&.c-pagination__item--active {
				margin: 0 rem(15px);
			}
		}

		@include mq(desktop) {
			margin: 0 rem(15px) 0 0;
		}
	}

	.c-pagination__item--page:not(.c-pagination__item--active) {
		@include mq(0, desktop) {
			display: none;
		}
	}

	.c-pagination__button {
		font-size: rem(24px);
		padding: rem(5px) rem(20px);

		@include mq(0,desktop) {
			padding-left: rem(20px);
			padding-right: rem(20px);
		}
	}

	.c-pagination__link {
		display: none;

		@include mq(desktop) {
			display: inline-block;
		}
	}
}

@include defer {
	.c-pagination__button.is-disabled {
		pointer-events: none;
		opacity: 0.3;
		cursor: not-allowed;
	}
}