﻿.c-medal-key {


}

.c-medal-key__list {

	@include critical {
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@include mq(0, desktop) {
			position: relative;
		}
	}
}

.c-medal-key__title {

	@include critical {
		margin: 0;
		padding: 0 spacing(small) 0 0;
		@include font-bold();
		text-transform: uppercase;

		@include mq(0, desktop) {
			font-size: rem(11px);
			line-height: rem(30px); // height of medal
			width: 100%;
			padding: 0 0 0 45px;

			&:not(:first-child) {
				margin: spacing(small) 0 0 0;
			}
		}
	}
}

.c-medal-key__type {

	@include critical {
		margin: 0;
		padding: 0;

		@include mq(0, desktop) {
			margin: 0;
			padding: 0;
			border: 0;
			position: absolute;
			width: 30px;
			top: 0;

			&:nth-child(4) {
				top: 45px;
			}

			&:nth-child(6) {
				top: 90px;
			}
		}

		&:not(:last-child) {

			@include mq(desktop) {
				border-right: 1px solid color(navy);
				padding: 0 spacing(small) 0 0;
				margin: 0 spacing(small) 0 0;
			}
		}
	}
}