﻿.c-social {

}

.c-social__title {

	@include critical {
		font-size: rem(12px);
		line-height: rem(18px);
		text-transform: uppercase;
		margin: 0 0 10px 0;
		padding: 0;
	}
}

.c-social__list {

	@include critical {
		margin: 0 -6px;
		padding: 0;
		list-style: none;
		display: flex;
	}
}

.c-social__item {

	@include critical {
		width: 20%;
		padding: 0 6px;
	}
}

.c-social__link {

	@include critical {
		display: block;
		@include ratio(1,1);
		position: relative;
	}

	@include defer {
		transition: opacity 0.25s ease;

		@include hover() {
			opacity: 0.75;
		}
	}
}

.c-social__icon {

	@include critical {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.c-social--in-section {

	@include critical {
		display: flex;
		align-items: center;
		justify-content: center;

		@include mq(wide) {
			justify-content: flex-end;
		}

		.c-social__title {
			margin: 0 rem(10px) 0 0;
		}

		.c-social__item {
			width: rem(44px);

			@include mq(wide) {
				width: rem(56px);
			}
		}
	}
}

.c-social--share-inline {

	@include critical {

		.c-social__item {

			@include mq(0, wide) {
				margin-right: rem(12px);
			}
		}
	}
}

.c-social--country {

	@include critical {

		.c-social {
			justify-content: flex-start;
		}
	}
}

.c-social--center {

	@include critical {

		.c-social__list {
			justify-content: center;
		}

		.c-social__item {
			width: auto;
		}
	}
}

.c-share__list--richtext {
	@include critical {
		@include mq(0, wide) {
			display: none;
		}
		position: absolute;
		top: 0;
		right: rem(-40px);

		.c-share__icon {
			width: rem(44px);
			height: rem(44px);
			margin: rem(-22px) 0 0 rem(-22px);
		}

		.c-share__item {
			margin: 0 0 rem(15px) 0;
		}
	}
}