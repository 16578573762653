﻿.c-footer {

	@include critical {
		
	}
}

.c-footer__inner {

	@include critical {
		background: color(navy);
		color: color(white);
		padding: spacing(default) 0;

		@include mq(wide) {
			padding: spacing(large) 0 spacing(default);
		}

		a {
			color: color(white);
			text-decoration: none;
		}
	}

	@include defer {

		a {

			@include hover() {
				text-decoration: underline;
				color: color(white);
			}
		}
	}
}

.c-footer__inner__inner {

	@include critical {
		position: relative;
	}
}

.c-footer__top {

	@include critical {
		

		@include mq(wide) {
			position: relative;
			padding: 0 0 0 #{130px + spacing(large)};
			min-height: 165px;
		}

		@include mq(1800px) {
			padding: 0 390px 0 #{65px + spacing(large)};
		}
	}
}

.c-footer__logo {

	@include critical {
		width: 58px;
		margin: 0 0 spacing(small) 0;
		padding: 0 0 0 10px;

		@include mq(wide) {
			width: 130px;
			position: absolute;
			top: -6px;
			left: 0;
			margin: 0;
			padding: 0;
		}

		@include mq(1800px) {
			left: -65px;
		}
	}
}

.c-footer__bottom {

	@include critical {
		text-transform: uppercase;
		padding: spacing(default) 0 0 0;

		@include mq(wide) {
			display: flex;
		}
	}
}

.c-footer__bottom__copyright {

	@include critical {
		margin: 0;
		padding: 0;
		font-size: rem(11px);
		line-height: rem(17px);
		@include font-medium();
	}
}

.c-footer__bottom__links {

	@include critical {
		margin: spacing(small) 0 0 0;

		@include mq(wide) {
			margin: 0 0 0 spacing(default);
		}
	}
}

.c-footer__utilities {

	@include critical {

		@include mq(wide) {
			padding: spacing(medium) 0 0 0;
			display: flex;
			align-items: center;
		}

		@include mq(1800px) {
			padding: 0;
			width: auto;
			width: 230px;
			display: block;
		}
	}
}

.c-footer__utilities__language {

	@include critical {
		margin: 0 0 spacing(default) 0;

		@include mq(wide) {
			margin: 0;
			width: 230px;
		}

		@include mq(1800px) {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

.c-footer__utilities__language__select {

	@include critical {
		text-transform: uppercase;
	}
}

.c-footer__utilities__social {

	@include critical {
		max-width: 300px;

		@include mq(wide) {
			max-width: 100%;
			width: 230px;
			position: relative;
			left: spacing(default);
		}

		@include mq(1800px) {
			position: absolute;
			bottom: 0;
			left: auto;
			right: 0;

			.c-social__title {
				position: absolute;
				transform: translate(-100%, -50%);
				padding: 0 spacing(small) 0 0;
				top: 50%;
			}
		}

		.c-social__title {

			@include mq(0, 1799px) {
				@include hide();
			}
		}
	}
}

.c-footer__lists {

	@include critical {
		margin: 0 0 spacing(medium) 0;

		@include mq(wide) {
			margin: 0;
		}
	}
}