﻿.c-curator {

	@include critical {

		.js & {
			min-height: 1500px;

			@include mq(600px) {
				min-height: 450px;
			}
		}
	}

	.crt-load-more-container {

		@include defer {
			display: none !important;
		}
	}

	.crt-post-date {

		@include defer {
			opacity: 0.8 !important;
		}
	}
}
