.c-article__inner {

	@include critical {
		display: block;
	}
}

.c-article__image {

	@include critical {
		display: block;
	}
}

.c-article__overview {

	@include critical {
		display: block;
		padding: 10px 0 0 0;
	}
}

.c-article__meta {

	@include critical {
		margin: 0;
		padding: 0;
		font-size: rem(12px);
		line-height: rem(17px);
	}
}

.c-article__meta--date {
	@include critical {
		margin-top: 5px;
	}
}

.c-article__meta__date {

	@include critical {
		text-transform: uppercase;
		@include font-medium();
		color: #737373;
		position: relative;
		display: inline-block;
	}
}

.c-article__meta__tag {

	@include critical {
		text-transform: uppercase;
		@include font-bold();
		color: #009DE0;
		text-decoration: none;
	}
}

.c-article__meta .c-article__meta__tag:nth-of-type(n + 2) {
	@include critical {
		&::before {
			content: ", ";
			color: color(navy);
			font-weight: normal;
		}
	}
}

a.c-article__meta__tag .c-article__meta__tag__text {

	@include defer {

		@include hover {
			text-decoration: underline;
		}
	}
}

.c-article__title:not(.t-alpha) {

	@include critical {
		margin: 0 0 5px 0;
		padding: 0;
		@include font-bold();
		font-size: rem(14px);
		line-height: rem(18px);

		@include mq(wide) {
			font-size: rem(20px);
			line-height: rem(28px);
		}
	}
}

.c-article__title__link {

	@include critical {
		color: color(navy);
		text-decoration: none;
	}

	@include defer {

		@include hover {
			text-decoration: underline;
		}
	}
}

.c-article__featured {

	@include critical {
		margin: 0;
		text-transform: uppercase;

		@include mq(desktop) {
			padding: 0 0 rem(10px) 0;
		}
	}
}

.c-article__date {

	@include critical {
		font-size: rem(14px);
		line-height: rem(20px);
		margin: rem(10px) 0 0 0;
		text-transform: uppercase;

		@include mq(desktop) {
			font-size: rem(20px);
			line-height: rem(26px);
		}
	}
}

.c-article__category {

	@include critical {
		font-size: rem(14px);
		line-height: rem(20px);
		@include font-bold();
		margin: rem(10px) 0 0 0;
		text-transform: uppercase;

		@include mq(desktop) {
			font-size: rem(28px);
			line-height: rem(34px);
		}
	}
}

.c-article__button {

	@include critical {
		margin: rem(15px) 0 0 0;

		@include mq(desktop) {
			margin: rem(22px) 0 0 0;
		}
	}
}

.c-article--emphasise {

	@include critical {

		.c-article__title {
			font-size: rem(18px);
			line-height: rem(22px);

			@include mq(wide) {
				font-size: rem(36px);
				line-height: rem(42px);
			}
		}
	}
}

@mixin articleCollapse() {
	position: relative;
	padding: 0 0 0 105px;
	min-height: 55px;

	.c-article__image {
		width: 95px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.c-article__overview {
		padding: 0;
	}

	.c-article__title {
		margin: 0 0 3px 0;
		font-size: rem(14px);
		line-height: rem(18px);
	}
}

.c-article--collapse {

	@include critical {

		@include mq(0, widest) {
			@include articleCollapse();
		}
	}
}

.c-article--collapse-desktop {

	@include critical {

		@include mq(0, desktop) {
			@include articleCollapse();
		}
	}
}

.c-article--small-title {
	@include critical {
		.c-article__title {
			@include mq(wide) {
				font-size: rem(16px);
				line-height: rem(22px);
				margin: 0 0 3px 0;
			}
		}
	}
}

.c-article--background {

	@include critical {
		background: color(white);
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);

		.c-article__overview {
			margin: 0;
			padding: rem(15px) rem(15px) rem(10px);
		}
	}
}

.c-article--featured {

	@include critical {

		.c-article__inner {
			position: relative;
			color: color(white);
			text-decoration: none;
		}

		.c-article__overview {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			align-items: flex-end;
			padding: spacing(default) spacing(small);

			@include mq(tablet) {
				padding: spacing(default) spacing(large);
			}

			@include mq(wide) {
				padding: rem(80px);
			}
		}

		.c-article__overview__inner {
			position: relative;
			width: 100%;

			@include mq(0, desktop) {
				padding-right: rem(65px);
			}
		}

		.c-article__button {

			@include mq(0, desktop) {
				position: absolute;
				right: 0;
				bottom: 0;
				margin: 0;
			}
		}

		.c-article__title {
			text-transform: uppercase;
			color: color(yellow);
			transition: color 0.25s ease;
			margin: rem(10px) 0 0 0;

			@include hover {
				color: color(white);
			}
		}

		.c-article__image {
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include gradient();
				z-index: 1;
			}
		}

		.c-article__date {
			color: color(white);
		}

		.c-article__meta__tag {
			color: color(white);

			@include mq(desktop) {
				font-size: rem(20px);
				line-height: rem(26px);
			}
		}

		.c-article__meta .c-article__meta__tag:before {
			color: color(white);
		}
	}
}

.c-article--mega {

	@include critical {

		@include mq(wide) {
			position: relative;

			.c-article__overview {
				position: absolute;
				bottom: 60px;
				left: 60px;
				z-index: 2;
			}

			.c-article__title__link {
				color: color(yellow);
				font-size: rem(36px);
			}

			.c-article__meta__date,
			.c-article__meta__tag {
				color: #FFF;
			}

			.c-article__meta {
				margin-bottom: rem(20px);
				font-size: rem(14px);
			}

			.c-image--article:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(45deg, #001a4d 0%, rgba(0,44,132,0.7) 53%, rgba(0,51,153,0.5) 100%);
				z-index: 1;
			}
		}
	}
}
