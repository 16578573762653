﻿.c-logo {

	@include critical {
		margin: 0;
	}
}

.c-logo__inner {

	@include critical {
		display: block;
		position: relative;
		@include ratio(133.705, 169.401);
	}
}

.c-logo__icon {

	@include critical {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
		transform-origin: top left;
		transition: transform 300ms;

		@include mq(0, wide) {
			.c-header.scrolling-down & {
				transform: scale(0.6) translateX(-2px) translateY(-2px);
			}
		}
	}
}