@include critical {

	a {
		text-decoration: underline;
		color: color(navy);
	}

	address {
		font-style: normal;
	}

	.t-alpha {
		font-size: rem(24px);
		line-height: rem(28px);
		@include font-bold();
		margin: 0;

		@include mq(desktop) {
			font-size: rem(34px);
			line-height: rem(38px);
		}

		@include mq(wide) {
			font-size: rem(70px);
			line-height: rem(75px);
		}
	}

	.t-alpha--emphasise {
		color: color(yellow);
		position: relative;
		display: inline-block;
		padding: 0 0 rem(10px) 0;

		@include mq(wide) {
			padding: 0 0 rem(35px) 0;
		}

		&:after {
			content: '';
			display: block;
			background: color(yellow);
			position: absolute;
			bottom: 0;
			left: 0;
			width: 45px;
			height: 2px;

			@include mq(wide) {
				width: 68px;
			}
		}
	}

	.t-beta {
		font-size: rem(24px);
		line-height: rem(28px);
		@include font-bold();
		margin: 0;
		text-transform: uppercase;

		@include mq(wide) {
			font-size: rem(46px);
			line-height: rem(46px);
		}
	}

	.t-gamma {
		font-size: rem(24px);
		line-height: rem(28px);
		@include font-medium();
		margin: 0;

		@include mq(wide) {
			font-size: rem(30px);
			line-height: rem(34px);
		}
	}

	.t-delta {
		font-size: rem(18px);
		line-height: rem(24px);
		@include font-medium();
		margin: 0;

		@include mq(wide) {
			font-size: rem(26px);
			line-height: rem(30px);
		}
	}

	.t-epsilon {
		font-size: rem(18px);
		line-height: rem(24px);
		@include font-default();
		margin: 0;

		@include mq(wide) {
			font-size: rem(26px);
			line-height: rem(30px);
		}
	}

	.t-divider {
		padding-bottom: spacing(small);
		position: relative;
	}

	.t-divider:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		background-color: color(navy);
		width: 58px;
	}

	.t-zeta {
		font-size: rem(17px);
		line-height: rem(20px);
		@include font-default();
		margin: 0;

		@include mq(wide) {
			font-size: rem(22px);
			line-height: rem(30px);
		}
	}

	.t-no-text-transform {
		text-transform: none;
	}

	strong, b, .t-bold {
		@include font-bold();
	}

	.t-highlight-link {
		color: color(light-blue);
		@include font-bold();
		text-decoration: none;
	}

	.t-highlight-link-alt {
		@include font-bold();
		text-decoration: none;
	}
}

@include defer {

	a {
		@include focus();
		text-decoration: underline;

		@include hover {
			text-decoration: none;
		}

		&.t-highlight-link,
		&.t-highlight-link-alt {

			@include hover {
				text-decoration: underline;
			}
		}
	}
}
