﻿.c-nav {

	@include critical {
		position: relative;
	}
}

.c-nav__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		height: 100%;
		border-bottom: 1px solid #e4b001;

		@include mq(wide) {
			display: flex;
			align-items: center;
			border-bottom: none;
			background-color: color(navy);
		}
	}
}

.c-nav__item {

	@include critical {
		position: relative;
		margin: 0;
		padding: 0;
		margin-right: rem(10px);
		white-space: nowrap;

		@include mq(0, wide) {
			border-top: 1px solid #e4b001;
		}

		@include mq(wide) {
			height: rem(84px);
			background-color: color(navy);
			margin-right: 0;
		}
	}
}

.c-nav__link {

	@include critical {
		display: flex;
		align-items: center;
		width: 100%;
		text-transform: uppercase;
		text-decoration: none;
		padding: rem(15px) rem(25px);
		font-size: rem(15px);
		flex: 1;
		@include font-medium();
		z-index: 100;
		border: none;
		background: transparent;
		border-radius: none;
		-webkit-appearance: none;
		@include focus();
		cursor: pointer;
	}

	@include defer {
		&[aria-expanded="true"] {
			color: color(navy);

			.c-nav__link-icon {
				color: color(navy);
				transform: rotate(-180deg);
			}

			@include mq(wide) {
				background-color: color(yellow);
			}
		}
	}

	@include critical {
		@include mq(wide) {
			position: relative;
			background-color: color(navy);
			color: color(white);
			padding: rem(spacing(default)) rem(15px);
			height: 100%;
			margin-right: rem(10px);

			.c-nav__link-icon {
				margin-left: rem(5px);
			}

			&.c-nav__link--current {
				color: color(yellow);
			}

			&.c-nav__link--current:before {
				content: '';
				position: absolute;
				top: 0;
				left: 25px;
				right: 25px;
				background-color: color(yellow);
				height: 4px;
			}
		}

		@include mq(widest) {
			padding: rem(spacing(default)) rem(25px);
		}
	}
}

.c-nav__link-icon {
	@include critical {
		width: rem(12px);
		height: rem(12px);
		margin-left: auto;
		transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55);

		@include mq(wide) {
			color: color(white);

			.c-nav__sub-nav-item & {
				color: color(navy);
			}
		}
	}
}

.c-nav__link-icon--current {
	@include critical {
		@include mq(wide) {
			color: color(yellow);
		}
	}
}

.c-nav__link-toggle {
	@include critical {
		position: relative;
		z-index: 100;
		background-color: transparent;
		padding: 0 rem(10px);
		margin-left: rem(-30px);
		border: none;
		cursor: pointer;
		height: 44px;
		border-radius: 0;
		-webkit-appearance: none;
		appearance: none;
		transition: transform 300ms;
	}
}
@include critical {
	.c-nav__sub-nav-container {
		max-height: 0;
		z-index: 99;
		transition: all 300ms;

		@include mq(0, wide) {
			overflow: hidden;
		}

		@include mq(wide) {
			visibility: hidden;
			max-height: none;
			transform: translateY(-150%);
			transition: none;
		}
	}
}

@include defer {

	.c-nav__link-toggle[aria-expanded=true] .c-nav__link-icon {
		transform: rotate(-180deg);
	}

	.c-nav__link-toggle--subnav {
		@include mq(0, wide) {
			transform: rotate(90deg);
		}
	}

	.c-nav__sub-nav-list {
		list-style: none;
		margin: 0;
		padding: 0;

		@include mq(wide) {
			background-color: color(yellow);

			.c-nav__sub-sub-container & {
				margin: 0 rem(10px);
			}
		}
	}

	.c-nav__sub-nav-item {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include mq(wide) {
			flex-direction: row;
		}
	}

	.c-nav__sub-nav-link {
		display: flex;
		width: 100%;
		text-transform: uppercase;
		text-decoration: none;
		padding: rem(15px) rem(40px);
		transition: background-color 300ms;
		border: none;
		cursor: pointer;
		background-color: transparent;
		color: color(navy);
		text-align: left;

		@include mq(0, wide) {
			.c-nav__link-icon {
				transform: rotate(90deg);
			}
		}

		&[aria-expanded=true] {

			.c-nav__link-icon {
				transform: rotate(-90deg);

				@include mq(wide) {
					transform: rotate(-180deg);
				}
			}
		}

		@include focus();

		@include mq(wide) {
			background-color: color(yellow);
			padding: rem(10px) rem(10px);

			&[aria-expanded=true] {
				background-color: #E5B209;
			}
		}

		@include mq(widest) {
			padding: rem(10px) rem(20px);
		}

		@include hover() {
			background-color: #E5B209;
		}
	}

	@include mq(wide) {
		.c-nav__sub-nav-link--large {
			padding: rem(20px);
		}
	}

	@include mq(widest) {
		.c-nav__sub-nav-link--large {
			padding: rem(20px) rem(30px);
		}
	}

	.c-nav__sub-nav-title {
		@include font-bold();
		text-transform: uppercase;
		padding: 0 rem(20px) rem(15px) rem(20px);
		margin: 0 rem(10px) rem(15px) rem(10px);
		border-bottom: 1px solid #e4b001;

		@include mq(wide, widest) {
			padding: 0 rem(10px) rem(15px) rem(10px);
		}
	}

	.c-nav__sub-sub-container {
		@include mq(0, wide) {
			display: block;
			width: 100%;
			max-height: 0;
			padding: rem(20px) 0 0 rem(40px);
			overflow: hidden;
			transition: max-height 300ms;
		}
	}

	.c-nav__sub-nav-container[aria-expanded=true] {
		transform: translateY(0);
		visibility: visible;
		max-height: 10000px; // this is overwritten by JavaScript for animation
	}

	.c-nav__sub-sub-container[aria-expanded=true] {
		display: block;
		width: 100%;

		@include mq(0, wide) {
			max-height: 10000px; // this is overwritten by JavaScript for animation
		}

		@include mq(wide) {
			visibility: visible;
			transform: translateX(0);
			opacity: 1;
		}
	}

	@include mq(0, wide) {
		.c-nav__sub-sub-container-inner .c-nav__sub-nav-col:not(:first-child) {
			padding-top: rem(10px);
		}
	}
}

@include mq(wide) {

	@include defer {

		.c-nav__sub-nav-container {
			position: absolute;
			top: $headerHeightDesktop;
			left: 0;
			background-color: color(yellow);
			white-space: normal;
			box-shadow: 0 rem(16px) rem(20px) 0 rgba(0,0,0,0.16);
		}

		.c-nav__sub-nav {
			display: flex;
			width: 100%;
			padding: rem(10px) 0;
		}

		.c-nav__sub-nav-col {
			display: flex;
			flex-direction: column;
			flex: 1 0 auto;
			width: rem(200px);

			.c-nav__sub-sub-container-inner & {
				padding-top: rem(30px);
			}
		}

		.c-nav__sub-nav-col:not(:last-child) {
			border-right: 1px solid #e4b001;
		}

		.c-nav__sub-sub-container {
			visibility: hidden;
			transform: translateX(-#{rem(50px)});
			background-color: color(yellow);
			position: absolute;
			top: 0;
			left: rem(200px);
			bottom: 0;
			z-index: 99;
			width: auto;
			/* IE needs the sizes set*/
			&.c-nav__sub-sub-container--3-col {
				width: rem(600px);
			}

			&.c-nav__sub-sub-container--2-col {
				width: rem(400px);
			}

			&.c-nav__sub-sub-container--1-col {
				width: rem(200px);
			}

			transition: none;
		}

		.c-nav__sub-sub-container-inner {
			position: relative;
			display: flex;
			min-height: 100%;
			background-color: color(yellow);
			border-left: 1px solid #e4b001;
		}
	}
}

@include mq(widest) {
	@include defer {
		.c-nav__sub-nav-col {
			width: rem(250px);
		}

		.c-nav__sub-sub-container {
			left: rem(250px);

			&.c-nav__sub-sub-container--3-col {
				width: rem(750px);
			}

			&.c-nav__sub-sub-container--2-col {
				width: rem(500px);
			}

			&.c-nav__sub-sub-container--1-col {
				width: rem(250px);
			}
		}
	}
}

@include defer {
	.mobile-nav-open,
	.mobile-nav-open body {
		overflow: hidden;
		position: fixed;
		background-color: color(yellow);
	}
}
