﻿.c-text-input,
.autocomplete__input {

	@include critical {
		display: block;
		width: 100%;
		border: 2px solid color(navy);
		height: 50px;
		margin: 0;
		color: color(navy);
		@include appearance-none();
		background: color(white);
		@include font-default();
		line-height: normal;
		padding: 0 20px;
		font-size: rem(16px); // 16 or larger to stop iOS zooming in on focus
		border-radius: 0;

		@include placeholder() {
			color: #9A9A9A;
			opacity: 1;
		}

		@include mq(wide) {
			font-size: rem(14px);
		}
	}

	@include defer {

		&:focus {
			border-color: color(yellow);
			outline: none;
		}

		&.is-error,
		&[aria-invalid="true"] {
			color: color(red);
			border-color: color(red);

			@include placeholder() {
				color: color(red);
			}
		}
	}
}

.c-text-input--secondary {
	@include critical {
		background: color(yellow);
		color: color(navy);

		@include placeholder() {
			color: color(navy);
			opacity: 1;
		}
	}

	@include defer {

		&:focus:not([aria-invalid="true"]) {
			border-color: color(navy);
		}
	}
}


/* Change Autocomplete styles in Chrome*/
.c-text-input--secondary:-webkit-autofill,
.c-text-input--secondary:-webkit-autofill:hover,
.c-text-input--secondary:-webkit-autofill:focus {
	@include defer {
		box-shadow: 0 0 0 100px color(yellow) inset; 
	}
}

.c-text-input--textarea {

	@include critical {
		height: 135px;
		padding-top: 15px;
		padding-bottom: 15px;
		resize: none;
		overflow: auto;

		@include mq(wide) {
			height: 200px;
		}
	}
}