﻿$statsPadding: 40px;
$statsPaddingLarge: 60px;

.c-stats__inner {

	@include critical {
		padding: rem(30px) 0 0 0;

		@include mq(desktop) {
			padding: rem(40px) 0 0 0;
		}

		@include mq(wide) {
			padding-left: rem(60px);
			padding-right: rem(60px);
		}
	}
}

.c-stats__list {

	@include critical {
		margin: 0;
		padding: 0 rem($statsPadding);
		display: flex;
		flex-wrap: wrap;

		@include mq(desktop) {
			padding: 0 rem($statsPaddingLarge);
		}
	}
}

.c-stats__list__title {

	@include critical {
		margin: 0;
		padding: 0 0 rem(15px) 0;
		@include font-bold();
		font-size: rem(16px);
		line-height: rem(16px);
		text-align: center;
		text-transform: uppercase;
		flex-basis: 100%;

		&:not(:first-child) {
			margin: rem(30px) 0 0 0;

			@include mq(desktop) {
				margin: rem(100px) 0 0 0;
			}
		}
	}
}

.c-stats__list__value {

	@include critical {
		margin: 0;
		padding: 0;
		width: 50%;
		position: relative;
		height: 10px;
		background: color(yellow);
		font-size: rem(20px);
		line-height: rem(20px);
		@include font-bold();
		color: color(navy);

		@include mq(desktop) {
			font-size: rem(30px);
			line-height: rem(30px);
		}

		+ .c-stats__list__value {
			background: color(navy);

			.c-stats__list__value__number {
				left: auto;
				right: -$statsPadding;

				@include mq(desktop) {
					right: -$statsPaddingLarge;
				}
			}
		}
	}
}

.c-stats__list__title + .c-stats__list__value--empty {

	@include critical {
		width: 1px !important;
		margin-bottom: -10px;
		background: none;
	}
}

.c-stats__list__value__number {

	@include critical {
		position: absolute;
		top: -5px;
		left: -$statsPadding;

		@include mq(desktop) {
			left: -$statsPaddingLarge;
			top: -10px;
		}
	}
}

.c-stats__title {

	@include critical {
		display: flex;
		justify-content: space-between;
		font-size: rem(18px);
		line-height: rem(24px);
		text-transform: uppercase;
		@include font-bold();
		color: color(navy);

		@include mq(desktop) {
			font-size: rem(26px);
			line-height: rem(32px);
		}
	}
}

.c-stats__title__team {

	@include critical {
		margin: 0;
	}
}