﻿.c-image-list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
}

.c-image-list__item {

	@include critical {
		padding: spacing(small);

		@include mq(wide) {
			padding: spacing(large);
		}

		img {
			max-width: 150px;
			max-height: 55px;

			@include mq(wide) {
				max-width: 200px;
				max-height: 115px;
			}
		}
	}
}

.c-image-list__link {

	@include critical {
		display: block;
	}

	@include defer {
		transition: opacity 0.25s ease;

		@include hover() {
			opacity: 0.75;
		}
	}
}