﻿.c-radio {

	@include critical {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: rem(14px);
		line-height: 1;
	}
}

.c-radio__radio {
	@include critical {
		display: inline-flex;
		align-items: center;
		border: 2px solid color(navy);
		width: rem(30px);
		height: rem(30px);
		border-radius: 100%;
		margin-right: spacing(tiny);
	}
}

.c-radio__radio-icon {
	@include critical {
		width: 13px;
		height: 13px;
		margin: 0 auto;
		display: none;
	}
}


input[type=radio]:checked + .c-radio__radio {
	@include defer {

		&:before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			background: color(navy);
			margin: 0 auto;
			border-radius: 100%;
		}
	}
}

.c-radio__input {

	@include critical {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.001;
	}
}
