﻿.c-countdown {

	@include critical {
		display: flex;
		justify-content: center;
	}
}

.c-countdown__number {

	@include critical {
		display: block;
		color: color(white);

		&:not(:first-child) {
			padding: 0 0 0 rem(15px);
			position: relative;

			@include mq(desktop) {
				padding: 0 0 0 rem(30px);
			}
		}
	}
}

.c-countdown__number__inner {

	@include critical {
		background: color(navy);
		font-size: rem(24px);
		line-height: rem(24px);
		@include font-bold();
		border: 2px solid color(white);
		border-radius: 8px;
		padding: rem(10px) rem(5px);
		display: block;
		box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.35);

		@include mq(desktop) {
			font-size: rem(29px);
			line-height: rem(29px);
		}

		@include mq(wide) {
			font-size: rem(39px);
			line-height: rem(39px);
			border-width: 4px;
			padding: rem(20px) rem(5px);
			border-radius: 12px;
		}

		.c-countdown__number:not(:first-child) & {

			&:before {
				content: ':';
				position: absolute;
				left: rem(3px);
				font-family: Arial, sans-serif;

				@include mq(desktop) {
					left: rem(9px);
				}

				@include mq(wide) {
					left: rem(8px);
				}
			}
		}
	}
}

.c-countdown__number__abbr {

	@include critical {
		display: block;
		font-size: rem(9px);
		line-height: rem(9px);
		text-transform: uppercase;
		margin: rem(8px) 0 0 0;
		text-align: center;

		@include mq(desktop) {
			font-size: rem(11px);
			line-height: rem(11px);
		}
	}
}