﻿.c-error-message {
	@include critical {
		display: block;
		color: #B40C0C;
		@include font-medium();
		font-size: rem(14px);
		margin-top: rem(5px);
		text-align: left;
	}
}
