﻿$grid-gutter: spacing(default);
$grid-gutter-small: spacing(small);
$grid-col: 100/12;

@mixin grid-column($cols) {
	width: (($grid-col * $cols) * 1%);
}

@mixin grid-push($cols) {
	position: relative;
	left: (($grid-col * $cols) * 1%);
}

@include critical {

	.c-grid {
		margin-left: -$grid-gutter;
		margin-bottom: -$grid-gutter;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}

	.c-grid__col {
		padding-left: $grid-gutter;
		width: 100%;
		margin-bottom: $grid-gutter;
		flex-shrink: 1;
		display: flex;

		.safari & {
			margin-left: -.5px;
			margin-right: -.5px;
		}
	}

	@include mq(0, desktop) {
		.c-grid__col--order-1-mobile {
			order: 1;
		}

		.c-grid__col--order-2-mobile {
			order: 2;
		}
	}

	.c-grid__col__inner {
		width: 100%;
	}

	.c-grid--flush {
		margin: 0;

		> .c-grid__col {
			margin-bottom: 0;
			padding-left: 0;
		}
	}

	.c-grid--footer-accordion {

		@include mq(0, wide) {
			margin: 0;

			> .c-grid__col {
				margin-bottom: 0;
				padding-left: 0;
				border-bottom: 1px solid color(white);
			}
		}
	}

	.c-grid--small-mobile {

		@include mq(0, wide) {
			margin-left: -$grid-gutter-small;
			margin-bottom: -$grid-gutter-small;

			> .c-grid__col {
				padding-left: $grid-gutter-small;
				margin-bottom: $grid-gutter-small;
			}
		}
	}

	.c-grid--form {
		margin-left: -#{spacing(small)};
		margin-bottom: -#{spacing(small)};

		@include mq(desktop) {
			margin-left: -#{spacing(default)};
			margin-bottom: -#{spacing(default)};
		}

		@include mq(wide) {
			margin-left: -#{spacing(massive)};
			margin-bottom: -#{spacing(massive)};
		}

		@include mq(widest) {
			margin-left: -#{rem(120px)};
			margin-bottom: -#{rem(120px)};
		}

		> .c-grid__col {
			padding-left: spacing(small);
			margin-bottom: spacing(small);

			@include mq(desktop) {
				padding-left: spacing(default);
				margin-bottom: spacing(default);
			}

			@include mq(wide) {
				padding-left: spacing(massive);
				margin-bottom: spacing(massive);
			}

			@include mq(widest) {
				padding-left: rem(120px);
				margin-bottom: rem(120px);
			}
		}
	}

	.c-grid--contact-cards {

		@include mq(0, desktop) {
			margin: 0;

			> .c-grid__col {
				margin-bottom: 0;
				padding-left: 0;

				&:not(:first-child) {

					.c-card {
						border-top: 0;
					}
				}
			}
		}
	}

	.c-grid--center {
		justify-content: center;
	}

	.c-grid--valign {
		align-items: center;
	}

	.c-grid--switch {

		> .c-grid__col {

			&:first-child {
				order: 2;
			}
		}
	}

	@for $i from 1 through 12 {
		.c-grid__col--#{$i} {
			@include grid-column($cols: $i);
		}

		.c-grid__col--push--#{$i} {
			@include grid-push($cols: $i);
		}
	}

	@each $name, $value in $breakpoints {

		@media (min-width: $value + px) {

			.c-grid--switch-#{$name} {

				> .c-grid__col {

					&:first-child {
						order: 2;
					}
				}
			}

			@for $i from 1 through 12 {

				.c-grid__col--#{$i}-#{$name} {
					@include grid-column($cols: $i);
				}

				.c-grid__col--push-#{$i}-#{$name} {
					@include grid-push($cols: $i);
				}
			}
		}
	}
}
