﻿.c-image {

	@include critical {
		position: relative;
		@include ratio(16,9);
		display: block;
		overflow: hidden;

		img {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			display: block;
		}
	}
}

.c-image--square {

	@include critical {
		@include ratio(1,1);
	}
}

.c-image--article {

	@include critical {
		@include ratio(817,467);
	}
}

.c-image--thumbnail {

	@include critical {
		@include ratio(212,118);
	}
}

.c-image--slide {

	@include critical {
		@include ratio(1255,700);
	}
}

.c-image--gallery {

	@include critical {
		@include ratio(1420,815);
	}
}

.c-image--article-featured {

	@include critical {
		@include ratio(375,265);

		@include mq(tablet) {
			@include ratio(1425,780);
		}
	}
}

.c-image--article-shallow {

	@include critical {
		@include ratio(334,188);
	}
}

.c-image--hover {

	@include defer {
		overflow: hidden;

		img {
			transition: transform 0.5s ease;
		}

		@include hover {

			img {
				transform: scale(1.05);
			}
		}
	}
}

.c-image--cta-card {

	@include critical {
		@include ratio(454,277);
	}
}


.c-image--simple-banner {

	@include critical {
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		@include mq(middle) {
			@include ratio(1920,550);
		}
	}
}
