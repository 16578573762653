﻿.c-video-link {

	@include critical {
		display: block;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 54px;
			height: 54px;
			background: color(yellow);
			border-radius: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -27px 0 0 -27px;
			z-index: 2;
		}

		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			border-left: 14px solid color(navy);
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -8px 0 0 -5px;
			z-index: 2;
		}
	}
}

.c-video-link--large {

	@include critical {

		@include mq(wide) {

			&:before {
				width: 76px;
				height: 76px;
				margin: -38px 0 0 -38px;
			}

			&:after {
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 16px solid color(navy);
				margin: -10px 0 0 -7px;
			}
		}
	}
}

.c-video-link--large {

	@include critical {

		@include mq(wide) {

			&:before {
				width: 76px;
				height: 76px;
				margin: -38px 0 0 -38px;
			}

			&:after {
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 16px solid color(navy);
				margin: -10px 0 0 -7px;
			}
		}
	}
}
