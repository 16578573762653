﻿.c-alert {
	@include critical {
		border-width: 2px;
		border-radius: 2px;
		border-style: solid;
		padding: rem(spacing(small));
		font-size: rem(16px);
		margin: 0 0 rem(spacing(default)) 0;
		@include font-medium();
	}
}


.c-alert--success {
	@include critical {
		border-color: #BFDDC5;
		background-color: #EDF7EA;
		color: #287128;
	}
}


.c-alert--error {
	@include critical {
		border-color: #FFCCCC;
		background-color: #FCE8E8;
		color: #B40C0C;
	}
}
