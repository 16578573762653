﻿.c-game-score {

	@include critical {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.c-game-score__image {

	@include critical {
		display: block;
		width: 40px;
		height: auto;
	}
}

.c-game-score__score {

	@include critical {
		padding: 0 rem(15px);
		font-size: rem(22px);
		line-height: rem(22px);
		@include font-black();

		@include mq(desktop) {
			padding: 0 rem(60px);
			font-size: rem(36px);
			line-height: rem(36px);
			padding: 0 rem(30px);
		}
	}
}

.c-game-score__title {

	@include critical {
		margin: 0;
		font-size: rem(20px);
		line-height: rem(20px);
		@include font-bold();

		@include mq(desktop) {
			font-size: rem(30px);
			line-height: rem(30px);
		}
	}
}

.c-game-score__versus {

	@include critical {
		text-decoration: none !important;
	}
}