﻿.c-tabs-wrapper {

	@include critical {

		@include mq(900px) {
			position: relative;
			padding: rem(32px) 0 0 0;
		}
	}
}

.c-tabs-wrapper__controls {

	@include critical {
		margin: 0 0 rem(30px) 0;

		@include mq(900px) {
			position: absolute;
			top: 0;
			right: 0;
			width: 160px;
		}

		@include mq(wide) {
			width: 260px;
		}
	}
}

.c-tabs-wrapper__controls__inner {

	@include critical {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include mq(800px) {
			flex-direction: row;
		}
	}
}

.c-tabs-wrapper__controls__item {
	@include critical {
		@include mq(0, 800px) {
			margin: 0 0 rem(5px) 0;
		}
	}
}

.c-tabs-wrapper--wide {

	.c-tabs-wrapper__controls {

		@include critical {

			@include mq(0, wide) {
				width: auto;
				position: static;
			}

			@include mq(wide) {
				width: 600px;
			}
		}
	}

	.c-tabs-wrapper__controls__inner {
		@include critical {
			@include mq(800px, wide) {
				margin-left: rem(-10px);
			}
		}
	}

	.c-tabs-wrapper__controls__item {

		@include critical {
			@include mq(800px) {
				flex: 1;
				margin-left: rem(10px);
			}
		}
	}
}

.c-tabs-wrapper--collapse {

	@include critical {

		@include mq(900px) {
			padding: 0;
		}
	}
}