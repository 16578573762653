.c-breadcrumbs {
	@include critical {
		background: #dce1e5;
		color: color(navy);
		text-align: center;
	}

	&__inner {
		@include critical {
			margin: 0 auto;
			text-align: left;
			padding-right: 0;
		}
	}

	&__menu {
		@include critical {
			font-size: 0;
			line-height: 0;
			margin-left: -10px;
			padding: rem(20px);
			white-space: nowrap;
			display: flex;
	
			@include mq(wide) {
				align-items: center;
				padding: rem(20px) 0 rem(20px) rem(70px);
			}
	
			@include mq(widest) {
				padding-left: rem(160px);
			}
		}

		&__item {
			display: none;
			font-size: rem(14px);
			line-height: rem(22px);
			position: relative;
			z-index: 1;

			@include mq(desktop) {
				display: inline-block;
			}

			&:before {
				background: url(/images/sprite.svg#icon-chevron-right) no-repeat center center;
				// Grey chevron:
				filter: brightness(0) saturate(100%) invert(54%) sepia(21%) saturate(310%) hue-rotate(180deg) brightness(93%) contrast(89%);
				content: "";
				display: none;
				height: 10px;
				width: 10px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 5px;
				top: 5px;
				rotate: -90deg;
				z-index: 1;
			}

			&:not(:last-child) {
				padding-right: 20px;

				&:before {
					display: block;
				}
			}

			&:last-child {
				white-space: normal; // Allow text wrapping for the last item
			}

			&.active {
				&:before {
					// Navy chevron:
					filter: brightness(0) saturate(100%) invert(12%) sepia(15%) saturate(4802%) hue-rotate(191deg) brightness(95%) contrast(114%);
				}
			}
		}

		&__link {
			color: color(navy);
			padding: 0 10px;
			text-decoration: none;
			display: inline-block;

			&:hover,
			&:focus {
				color: rgba(color(navy), .6);
			}

			&.active {
				cursor: default;
				margin: 0;
				color: color(navy);

				&:hover {
					color: color(navy);
				}
			}
		}
	}
}

// Mobile tweaks
@include mq(0, desktop) {
	.c-breadcrumbs__menu__item--display {
		display: inline-block !important;
	}
}