﻿.c-profile {

	@include critical {
		background: color(white);
		height: 100%;
		box-shadow: 0px 0px 10px 5px rgba(#D3DAE2, 0.35);
	}
}

.c-profile__content {

	@include critical {
		padding: spacing(default);
	}
}

.c-profile__title {

	@include critical {
		margin: 0 0 spacing(small) 0;
	}
}