﻿.c-generic-image {

	@include critical {
		display: block;

		img {
			max-width: 100%;
			display: block;
			height: auto;
			margin: 0 auto;
		}
	}
}