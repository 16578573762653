﻿.c-load-more {

	@include critical {
		margin: 0;
		padding: rem(30px) 0 0 0;
		text-align: center;

		.no-js & {
			display: none;
		}

		@include mq(desktop) {
			padding: rem(50px) 0 0 0;
		}
	}
}

.c-load-more--flush {
	@include critical {
		padding: 0;
	}
}