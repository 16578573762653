﻿.c-mailing-list {
	@include critical {
		background-color: color(yellow);
		color: color(navy);
		padding: rem(spacing(medium)) 0;
		text-align: center;

		@include mq(wide) {
			text-align: left;
			padding: rem(spacing(large)) 0;
		}
	}
}

.c-mailing-list__title {
	@include critical {
		margin: 0 0 spacing(tiny) 0;
		@include mq(wide) {
			margin: rem(25px) 0 rem(20px) 0;
		}
	}
}

.c-mailing-list__button {
	@include critical {
		@include mq(0, wide) {
			margin: rem(spacing(default)) 0 0 0;
		}
	}
}

@include mq(wide) {

	.c-mailing-list__form {
		@include critical {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			position: relative;
			padding-right: 137px;
		}
	}


	.c-mailing-list__button {
		@include critical {
			position: absolute;
			top: rem(29px);
			right: 0;
			margin: 0;
		}
	}

	.c-mailing-list__fields {
		@include critical {
			display: inline-flex;
			margin: calc(-1 * 10px) 0 rem(spacing(small)) calc(-1 * 10px);
			width: calc(100% + 10px);

			.c-form-row {
				margin: rem(10px) 0 0 rem(10px);
				flex: 1;
			}
		}
	}
}