﻿.c-back-to-top {

	@include critical {
		display: none;
	}

	@include defer {
		width: 45px;
		height: 95px;
		background: color(yellow);
		position: absolute;
		display: block;
		top: -#{spacing(default) + 20px}; // .c-footer__inner padding top
		right: spacing(small);
		box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.16);
		transition: background 0.25s ease;

		@include mq(tablet) {
			right: spacing(large);
		}

		@include mq(wide) {
			top: -#{spacing(large) + 100px}; // .c-footer__inner padding top
			height: 115px;
		}

		@include mq(1800px) {
			right: 0;
		}

		&:before {
			content: '';
			width: 2px;
			background: color(navy);
			height: 35px;
			position: absolute;
			top: 20px;
			left: 50%;
			margin: 0 0 0 -1px;

			@include mq(wide) {
				height: 45px;
			}
		}

		&:after {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			border: 2px solid color(navy);
			transform: rotate(-45deg);
			border-width: 2px 2px 0 0;
			position: absolute;
			top: 20px;
			left: 50%;
			margin: 0 0 0 -5.5px;
		}

		@include focus();

		@include hover() {

			&:before {
				animation: backLine 0.5s ease;
			}

			&:after {
				animation: backArrow 0.5s ease;
			}
		}
	}
}

.c-back-to-top__text {

	@include defer {
		color: color(navy);
		text-transform: uppercase;
		@include font-medium();
		font-size: rem(12px);
		line-height: rem(17px);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0 5px 10px 5px;
		text-align: center;
	}
}

@include defer {

	@keyframes backLine {
		0% {
			transform: translateY(0);
		}

		50% {
			transform: translateY(20px);
		}

		100% {
			transform: translateY(0);
		}
	}

	@keyframes backArrow {
		0% {
			transform: translateY(0) rotate(-45deg);
		}

		50% {
			transform: translateY(20px) rotate(-45deg);
		}

		100% {
			transform: translateY(0) rotate(-45deg);
		}
	}
}
