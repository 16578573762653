﻿.c-capsule {

	@include critical {
		display: inline-block;
		font-size: rem(9px);
		line-height: rem(16px);
		@include font-black();
		border: 1px solid color(white);
		padding: rem(2px) rem(7px) 0;
		border-radius: 3px;

		@include mq(desktop) {
			font-size: rem(11px);
			padding: rem(3px) rem(14px) rem(1px);
		}
	}
}
