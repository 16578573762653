﻿.c-label {

	@include critical {
		display: block;
		text-transform: uppercase;
		@include font-bold();
		font-size: rem(14px);
		text-align: left;
		cursor: pointer;
		margin: 0 0 5px 0;
	}
}

.c-label__req {

	@include critical {
		text-decoration: none !important;
	}
}