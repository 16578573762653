﻿.c-filters-full {
	@include critical {
		background-color: color(yellow);
	}
}

.c-filters-full__inner {
	@include critical {
		padding: rem(20px);

		@include mq(wide) {
			display: flex;
			align-items: center;
			padding: rem(20px) 0 rem(20px) rem(70px);
		}

		@include mq(widest) {
			padding-left: rem(160px);
		}
	}
}

.c-filters-full__inner--tight {

	@include critical {
		padding-top: 0;
	}
}

.c-filters-full__title {

	@include critical {
		@include mq(0, wide) {
			text-align: center;
			text-transform: uppercase;
			@include font-bold();
		}

		@include mq(wide) {
			line-height: rem(52px);
		}
	}
}

.c-filters-full__show-mobile {
	@include critical {
		@include mq(wide) {
			display: none;
		}
	}
}

.c-filters-full__mobile-button--full {
	@include critical {
		@include mq(0, wide) {
			width: 100%;
			margin-top: rem(10px);
			padding: rem(13px) rem(25px);

			.c-button__icon {
				margin-left: auto;
			}
		}
	}
}

.c-filters-full__mobile-button {
	@include mq(0, wide) {
		&[aria-expanded="true"] {
			.c-filters-full__mobile-button-open-icon {
				display: none;
			}
		}

		&[aria-expanded="false"] {
			.c-filters-full__mobile-button-close-icon {
				display: none;
			}
		}
	}
}

.c-filters-full__item--collapsed-mobile {
	@include critical {
		@include mq(0, wide) {
			max-height: 0;
			overflow: hidden;
			transition: max-height 300ms;

			&.is-open {
				max-height: 500px;
			}
		}

		@include mq(wide) {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	}
}

.c-filters-full__filter-form {
	@include critical {
		@include mq(wide) {
			display: flex;
		}
	}
}

.c-filters-full__filter-form-item {
	@include critical {
		@include mq(0, wide) {
			margin-bottom: rem(10px);
		}

		@include mq(wide) {
			margin-left: rem(20px);
		}
	}
}