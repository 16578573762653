﻿.c-match-preview__overview {

	@include critical {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 310px;
		margin: 0 auto;
	}
}

.c-match-preview__team {

	@include critical {
		text-align: center;
	}
}

.c-match-preview__name {

	@include critical {
		font-size: rem(16px);
		line-height: rem(22px);
		@include font-bold();
		text-transform: uppercase;
		margin: rem(5px) 0 0 0;
	}
}

.c-match-preview__versus {

	@include critical {
		padding: 0 rem(30px);
		text-decoration: none !important;
		@include font-bold();
		font-size: rem(26px);
		line-height: rem(26px);
		color: color(lighter-blue);
	}
}

.c-match-preview__details {

	@include critical {
		margin: rem(20px) 0 0 0;
		text-align: center;
	}
}

.c-match-preview__details__line {

	@include critical {
		margin: 0;
	}
}

.c-match-preview__details__line--title {

	@include critical {
		@include font-bold();
		font-size: rem(16px);
		line-height: rem(22px);
	}
}