﻿.c-bar-stat__inner {

	@include critical {
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
	}
}

.c-bar-stat__title {

	@include critical {
		margin: 0 0 rem(10px) 0;
		padding: 0;
		flex-basis: 100%;
		font-size: rem(10px);
		line-height: rem(12px);

		@include mq(largePhone) {
			font-size: rem(13px);
			line-height: rem(15px);
		}

		@include mq(desktop) {
			font-size: rem(16px);
			line-height: rem(18px);
		}
	}
}

.c-bar-stat__value {

	@include critical {
		margin: 0;
		padding: 0;
		width: 50%;
		height: rem(30px);
		position: relative;

		@include mq(largePhone) {
			height: rem(35px);
		}

		@include mq(desktop) {
			height: rem(40px);
		}

		@include mq(wide) {
			height: rem(50px);
		}
	}
}

.c-bar-stat__value__number {

	@include critical {
		display: block;
		height: rem(30px);
		line-height: rem(30px);
		padding: 0 rem(10px);
		position: absolute;
		top: 0;
		left: 0;
		@include font-medium();
		font-size: rem(10px);

		@include mq(largePhone) {
			font-size: rem(13px);
			height: rem(35px);
			line-height: rem(35px);
		}

		@include mq(desktop) {
			font-size: rem(16px);
			height: rem(40px);
			line-height: rem(40px);
		}

		@include mq(wide) {
			font-size: rem(18px);
			height: rem(50px);
			line-height: rem(50px);
		}
	}
}

.c-bar-stat__value--home {

	@include critical {
		background: color(yellow);
	}
}

.c-bar-stat__value--away {

	@include critical {
		background: color(navy);

		.c-bar-stat__value__number {
			left: auto;
			right: 0;
			color: color(white);
		}
	}
}

.c-bar-stat__value--empty {

	@include critical {
		background: transparent;

		.c-bar-stat__value__number {
			color: color(navy);
			padding: 0;
		}

		&.c-bar-stat__value--home {

			.c-bar-stat__value__number {
				left: 0;
			}
		}

		&.c-bar-stat__value--away {

			.c-bar-stat__value__number {
				right: 0;
			}
		}
	}
}