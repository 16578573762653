﻿.c-form {

	@include critical {

		.field-validation-error {
			color: color(red);
			margin: rem(5px) 0 0 0;

			&:not(:empty) {
				display: block;
			}
		}
	}
}

.c-form__row {

	@include critical {
		
		&:not(:last-child) {
			margin: 0 0 spacing(small);

			@include mq(desktop) {
				margin: 0 0 spacing(default) 0;
			}
		}
	}
}

.c-form__row--recaptcha {

	@include critical {
		padding: 5px 0 0 0;

		@include mq(0, 350px) {

			.g-recaptcha {
				transform: scale(0.8);
				transform-origin: 0 0;
				margin-bottom: -15px;
			}
		}
	}
}

.c-form--highlight {

	@include critical {
		background: color(white);
		box-shadow: 0px 2px 5px 0px rgba(188,188,188,0.15);
		padding: spacing(medium) rem(20px);

		@include mq(desktop) {
			padding: spacing(medium);
		}

		@include mq(wide) {
			padding: spacing(large) spacing(massive);
		}

		@include mq(widest) {
			padding: spacing(large) rem(120px);
		}
	}
}