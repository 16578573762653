﻿@include critical {

	.c-message-bar {
		background: color(yellow);
		display: none;

		@include mq(wide) {
			display: block;
		}
	}

	.c-message-bar__inner__inner {
		display: flex;
		align-items: center;
		padding: spacing(tiny) 0 spacing(tiny) 160px;
		white-space: nowrap;
	}

	.c-message-bar__links {
		margin-left: auto;
	}

	.c-message-bar__link-highlight {
		text-transform: uppercase;
		text-decoration: underline;
		margin-left: rem(20px);
		@include font-bold();
	}

	.c-message-bar__heading {
		text-transform: uppercase;
		margin-right: rem(25px);
	}

	.c-message-bar__copy {
		margin-right: rem(10px);
		@include font-medium();
		max-width: 50%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.c-message-bar__cta {
		text-decoration: none;
		@include font-bold();
	}

	.c-message-bar--mobile {
		padding: rem(spacing(small)) 0;

		@include mq(wide) {
			display: none;
		}
	}

	.c-message-bar__mobile-buttons {
		@include mq(0, wide) {
			display: flex;

			.c-button {
				flex: 1;
				padding-left: rem(10px);
				padding-right: rem(10px);
			}

			.c-button:first-child {
				margin-right: rem(5px);
			}
		}

		@include mq(desktop, wide) {
			.c-button {
				flex: none;
				min-width: 175px;
			}

			.c-button:first-child {
				margin-right: rem(15px);
			}

			.c-button:first-child {
				margin-left: auto;
			}
		}
	}
}

@include defer {

	.c-message-bar__cta {
		@include hover() {
			text-decoration: underline;
		}
	}
}