@include critical {

	.c-cms {

		> * {
			margin: 0;

			&:not(:first-child) {
				margin-top: spacing(default);
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			ul {
				padding: 5px 0 0 0;
			}

			li {
				padding-left: 25px;
				position: relative;

				&:not(:first-child) {
					margin-top: 5px;
				}

				&:before {
					content: '';
					display: block;
					width: 7px;
					height: 7px;
					border-radius: 100%;
					background: color(yellow);
					position: absolute;
					top: 9px;
					left: 0;
				}
			}
		}

		small {
			font-size: rem(12px);
		}

		blockquote {
			padding: 0 spacing(default);

			> p {
				margin: 0;

				&:first-child {
					font-size: rem(26px);
					line-height: rem(30px);
					@include font-bold();
					color: color(royal-blue);

					@include mq(wide) {
						font-size: rem(36px);
						line-height: rem(42px);
					}
				}

				&:last-child {
					@include font-bold();
					color: #9A9A9A;
					margin: spacing(small) 0 0 0;

					&:before {
						content: '- ';
					}
				}
			}

			@include mq(wide) {
				padding: 0 spacing(medium);
			}
		}

		img,
		iframe,
		object {
			max-width: 100%;
			margin: 0 auto;
			display: block;

			@include mq(0,tablet) {
				margin-left: #{rem(spacing(small) * -1)};
				margin-right: #{rem(spacing(small)* -1)};
				width: calc(100% + #{rem(spacing(small) * 2)});
				max-width: none;
			}
		}

		img {
			height: auto;
		}

		h2:not([class^="t-"]) {
			font-size: rem(24px);
			line-height: rem(28px);
			@include font-bold();
			text-transform: uppercase;

			@include mq(wide) {
				font-size: rem(46px);
				line-height: rem(46px);
			}
		}

		h3:not([class^="t-"]) {
			font-size: rem(24px);
			line-height: rem(28px);
			@include font-medium();

			@include mq(wide) {
				font-size: rem(30px);
				line-height: rem(34px);
			}
		}

		h4:not([class^="t-"]) {
			font-size: rem(18px);
			line-height: rem(24px);
			@include font-medium();

			@include mq(wide) {
				font-size: rem(26px);
				line-height: rem(30px);
			}
		}

		h5:not([class^="t-"]) {
			font-size: rem(18px);
			line-height: rem(24px);
			@include font-default();

			@include mq(wide) {
				font-size: rem(26px);
				line-height: rem(30px);
			}
		}

		table {
			border-collapse: collapse;
			width: 100%;

			td,
			th {
				border: 1px solid color(grey);
				padding: rem(10px);
				text-align: left;
			}

			tr:nth-child(even) {
				background-color: #eeeeee;
			}

			tbody {

				tr:first-child td {
					@include font-bold();
					text-transform: uppercase;
				}
			}
		}
	}

	.c-cms--tight {

		> * {

			&:not(:first-child) {
				margin-top: rem(5px);
			}
		}
	}

	.c-cms--flush {

		> * {
			margin: 0;

			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}

	.c-cms--center {
		text-align: center;
	}

	.c-cms--large {
		@include font-size-large();
	}

	.c-cms--small {
		@include font-size-small();
	}
}
