﻿@include critical {
	.c-cta-card {
		display: block;
	}

	.c-cta-card__content {
		display: flex;
		align-items: center;
		padding: rem(10px) rem(20px);

		@include mq(wide) {
			padding: rem(20px);
		}
	}

	.c-cta-card__icon {
		margin-left: auto;
	}
}

@include defer {
	.c-cta-card {
		text-decoration: none;
		box-shadow: 1px 1px 6px rgba(0,0,0,0.2);
		transition: box-shadow 300ms;

		@include hover {
			box-shadow: 1px 1px 10px rgba(0,0,0,0.3);

			.c-cta-card__icon {
				transform: translateX(5px);
			}
		}
	}

	.c-cta-card__content {
		color: color(navy);
	}

	.c-cta-card__text {
		text-decoration: none;
		@include font-bold();
		font-size: rem(16px);
		width: 100%;
		padding-right: rem(15px);

		@include mq(wide) {
			font-size: rem(24px);
		}
	}

	.c-cta-card__icon {
		transition: transform 300ms;
		height: rem(12px);
		width: rem(12px);

		@include mq(wide) {
			height: rem(23px);
			width: rem(23px);
		}
	}
}
