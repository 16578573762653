﻿.c-fixtures-table {

	@include critical {
		border-collapse: collapse;
		width: 100%;

		.js &.is-hidden {
			display: none;
		}

		&.is-hidden + .c-no-results {
			display: block;
		}

		tr {

			@include mq(0, desktop) {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid color(catskill-white);
			}
		}

		td,
		th {
			border-bottom: 1px solid color(catskill-white);
			vertical-align: middle;
			padding: rem(15px);

			@include mq(desktop) {

				&:nth-child(2),
				&:nth-child(4) {
					width: 15%;
				}
			}

			@include mq(0, desktop) {
				display: block;
				border: 0;
				padding-left: rem(5px);
				padding-right: rem(5px);

				&:nth-child(1) {
					width: 40%;
				}
			}

			@include mq(desktop, wide) {

				&:first-child {
					padding-left: 0;
				}
			}
		}

		.c-fixtures-table__title {
			font-size: rem(15px);
			line-height: rem(19px);
			color: color(navy);
			text-transform: uppercase;
			@include font-bold();
			margin: 0;

			@include mq(desktop) {
				font-size: rem(16px);
				line-height: rem(20px);
			}
		}

		.c-fixtures-table__versus {
			color: #BED4EA;
			@include font-bold();
			font-size: rem(11px);
			line-height: rem(11px);

			@include mq(desktop) {
				font-size: rem(17px);
				line-height: rem(17px);
			}
		}

		abbr.c-fixtures-table__versus {
			text-decoration: none;
		}

		.c-fixtures-table__info {

			@include mq(0, desktop) {
				font-size: rem(12px);
				line-height: rem(19px);
			}
		}

		.c-fixtures-table__title__team {

			@include mq(0, desktop) {

				&:before {
					content: attr(data-abbr);
				}
			}
		}

		.c-fixtures-table__title__team__inner {

			@include mq(0, desktop) {
				@include hide();
			}
		}

		.c-fixtures-table__image {
			display: block;
			width: 40px;
			height: auto;
		}

		td.c-fixtures-table__cta {

			@include mq(0, desktop) {
				padding: 0;
				width: 0;
				height: 0;
				flex-basis: 100%;
			}

			.c-button {

				@include mq(desktop) {
					min-width: rem(150px);
				}

				@include mq(wide) {
					min-width: rem(180px);
				}

				@include mq(0, desktop) {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.01;
				}

				@include mq(desktop, 1000px) {
					padding-left: rem(10px);
					padding-right: rem(10px);

					&.c-button--play {

						&:before,
						&:after {
							display: none;
						}
					}
				}
			}
		}

		td.c-fixtures-table__cta--right {
			@include mq(desktop) {
				text-align: right;
			}
		}
	}

	.c-fixtures-table__title__score {

		@include mq(0, desktop) {
			display: block;
		}

		&:before {
			content: '(';
		}

		&:after {
			content: ')';
		}
	}
}

.c-fixtures-table__row {

	@include critical {

		.js &.is-hidden {
			display: none;
		}
	}
}

.c-fixtures-table__detail-cell__inner {

	@include critical {
		display: flex;
		align-items: center;
		justify-content: center;

		.c-fixtures-table__versus {
			margin: 0 rem(20px);

			@include mq(0, 400px) {
				margin: 0 rem(5px);
			}
		}

		.c-image-text--right {
			text-align: right;
		}
	}
}

.c-fixtures-table__show-small {
	@include critical {
		@include mq(0, 940px) {
			display: none !important;
		}
	}
}

.c-fixtures-table__hide-large {
	@include critical {
		@include mq(940px) {
			display: none !important;
		}
	}
}