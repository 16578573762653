﻿.c-dynamic-stats {

	@include critical {

	}
}

.c-dynamic-stats__title-wrapper {

	@include critical {
		margin: 0 0 spacing(medium) 0;

		@include mq(desktop) {
			margin: 0 0 spacing(large) 0;
			display: flex;
			align-items: center;
		}
	}
}

.c-dynamic-stats__title {

	@include critical {
		font-size: rem(26px);
		line-height: rem(30px);
		@include font-bold();
		margin: 0 0 rem(20px) 0;
		text-transform: uppercase;
		text-align: center;

		@include mq(desktop) {
			margin: 0 rem(50px) 0 0;
			text-align: left;
		}
	}
}

.c-dynamic-stats__key {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		justify-content: center;

		@include mq(desktop) {
			justify-content: flex-start;
		}
	}
}

.c-dynamic-stats__key__item {

	@include critical {
		margin: 0;
		padding: 0 0 0 rem(50px);
		list-style: none;
		position: relative;
		line-height: rem(32px);
		@include font-bold();
		font-size: rem(14px);
		text-transform: uppercase;

		&:not(:first-child) {
			margin: 0 0 0 rem(30px);

			@include mq(desktop) {
				margin: 0 0 0 rem(40px);
			}
		}

		@include mq(desktop) {
			font-size: rem(16px);
		}

		&:before {
			content: '';
			display: block;
			width: 32px;
			height: 32px;
			background: color(yellow);
			position: absolute;
			top: 50%;
			left: 0;
			margin: -16px 0 0 0;
		}
	}
}

.c-dynamic-stats__key__item--away {

	@include critical {

		&:before {
			background: color(navy);
		}
	}
}

.c-dynamic-stats__donuts,
.c-dynamic-stats__bars {

	@include critical {
		display: flex;
		flex-wrap: wrap;
	}
}

.c-dynamic-stats__donuts__item,
.c-dynamic-stats__bars__item {

	@include critical {
		width: 50%;
		padding: spacing(small) spacing(small) 0 spacing(small);

		@include mq(tablet) {
			padding: spacing(medium) spacing(small) 0 spacing(small);
		}

		@include mq(desktop) {
			width: 33.333333333%;
		}

		@include mq(wide) {
			width: 25%;
		}

		@include mq(widest) {
			padding: spacing(medium) spacing(medium) 0 spacing(medium);
		}
	}
}