body {

	@include critical {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		@include font-default();
		@include font-size-default();
		color: color(navy);
		background: color(white);
		padding: 0;
		margin: 0;
	}
}
