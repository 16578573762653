﻿.c-image-text {

	@include critical {
		display: flex;
		align-items: center;
	}
}

.c-image-text--right {
	@include critical {
		justify-content: flex-end;
	}
}

.c-image-text__text {

	@include critical {
		padding: 0 0 0 rem(10px);

		@include mq(desktop) {
			padding: 0 0 0 rem(15px);
		}
	}
}

.c-image-text--switch {

	@include critical {

		.c-image-text__image {
			order: 2;
		}

		.c-image-text__text {
			padding: 0 rem(10px) 0 0;

			@include mq(desktop) {
				padding: 0 rem(15px) 0 0;
			}
		}
	}
}

.c-image-text--hide-image-small {

	@include critical {

		@include mq(0, 374px) {

			.c-image-text__image {
				display: none !important;
			}

			.c-image-text__text {
				padding: 0;
			}
		}
	}
}