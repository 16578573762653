﻿.c-video {

	@include critical {
		@include ratio(16,9);
		position: relative;

		iframe,
		object,
		embed,
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
		}
	}
}