﻿.c-select {

	@include critical {
		display: block;
		width: 100%;
		border: 0;
		margin: 0;
		padding: 13px 35px 13px 25px;
		color: color(navy);
		box-shadow: none;
		@include appearance-none();
		text-align: left;
		background: color(white);
		line-height: normal;
		@include font-medium();
		font-size: rem(16px);
		border-radius: 0;
		max-width: 100%;
		border: 2px solid color(navy);
		height: 50px;

		@include mq(wide) {
			font-size: rem(14px);
			padding: 14px 40px 14px 25px;
		}

		&:disabled {
			opacity: 0.5;
		}

		&::-ms-expand {
			display: none;
		}

		&::-ms-value {
			background: none;
			color: color(navy);
		}
	}

	@include defer {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNiAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiMwMDE0M2IiIGQ9Ik0yMy4zMDMtLjAwMkwxMi40NjcgMTAuODM0IDEuNjMtLjAwMi0uNDU0IDIuMDgybDEyLjkyMSAxMi45MiAyLjA4NC0yLjA4NEwyNS4zODcgMi4wODJ6Ii8+Cjwvc3ZnPgo=");
		background-position: calc(100% - 25px) 50% !important;
		background-repeat: no-repeat !important;
		background-size: 10px 10px !important;

		&:focus {
			border-color: color(yellow);
			outline: none;
		}

		&.is-error,
		&[aria-invalid="true"] {
			color: color(red);
			border-color: color(red);

			@include placeholder() {
				color: color(red);
			}
		}
	}
}

.c-select--alt {

	@include critical {
		color: color(white);
		background: color(navy);
		border: 1px solid color(white);

		&::-ms-value {
			color: color(white);
		}
	}

	@include defer {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNiAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik0yMy4zMDMtLjAwMkwxMi40NjcgMTAuODM0IDEuNjMtLjAwMi0uNDU0IDIuMDgybDEyLjkyMSAxMi45MiAyLjA4NC0yLjA4NEwyNS4zODcgMi4wODJ6Ii8+Cjwvc3ZnPgo=");
	}
}

.c-select--secondary {

	@include critical {
		color: color(navy);
		background: color(yellow);
		font-weight: 400;

		&::-ms-value {
			color: color(navy);
		}
	}

	@include defer {

		&:focus {
			border-color: color(navy);
			outline: initial;
		}

		&.is-error,
		&[aria-invalid="true"] {
			color: color(red);
			border-color: color(red);

			@include placeholder() {
				color: color(red);
			}
		}
	}
}