﻿@include critical {

	.c-search-result__list {
		margin: 0 0 rem(spacing(small)) 0;
		padding: 0;
		list-style: none;

		@include mq(desktop) {
			margin: 0 0 rem(spacing(large)) 0;
		}
	}

	.c-search-result__item {
		display: flex;
		align-items: center;
		padding: 0 0 rem(spacing(small)) 0;
		margin: 0 0 rem(spacing(small)) 0;

		@include mq(tablet, desktop) {
			align-items: flex-start;
		}

		@include mq(desktop) {
			padding: 0 0 rem(spacing(default)) 0;
			margin: 0 0 rem(spacing(default)) 0;
		}
	}

	.c-search-result__item:not(:last-child) {
		border-bottom: 1px solid color(light-grey);
	}

	.c-search-result__content {
		flex: 1;
	}

	.c-search-result__image {
		margin-right: rem(spacing(small));
		width: 95px;

		@include mq(desktop, wide) {
			width: 200px;
			margin-right: rem(spacing(default));
		}

		@include mq(wide) {
			width: 300px;
			margin-right: rem(spacing(large));
		}
	}

	.c-search-result__title {
		font-size: rem(14px);
		margin: 0 0 rem(5px) 0;

		@include mq(wide) {
			font-size: rem(22px);
		}
	}

	.c-search-result__description {
		@include mq(0, tablet) {
			display: none;
		}
	}

	.c-search-result__link {
		text-decoration: none;
		margin-bottom: rem(spacing(small));
	}
}


@include defer {
	.c-search-result__link {
		@include hover {
			text-decoration: underline;
		}
	}
}