﻿.c-checkbox {

	@include critical {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: rem(14px);
		line-height: 1;
	}
}

.c-checkbox__check {
	@include critical {
		display: inline-flex;
		align-items: center;
		border: 2px solid color(navy);
		width: rem(30px);
		height: rem(30px);
		margin-right: spacing(tiny);
	}
}

.c-checkbox__text {

	@include critical {
		display: inline-flex;
		align-items: center;
		width: calc(100% - #{rem(30px + spacing(small))});
		text-align: left;
	}
}

.c-checkbox__check-icon {
	@include critical {
		width: 18px;
		height: 13px;
		margin: 0 auto;
		display: none;
	}
}


input[type=checkbox]:checked + .c-checkbox__check {
	@include critical {
		.c-checkbox__check-icon {
			display: block;
		}
	}
}

.c-checkbox__input {

	@include critical {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.001;
	}
}