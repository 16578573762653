$detailBannerContentWidth: rem(540px);
$detailBannerContentWidthLarge: rem(730px);

.c-detail-banner {

	@include critical {

		@include mq(wide) {
			background: #edf0f2;
		}
	}
}

.c-detail-banner__inner {

	@include critical {

		@include mq(wide) {
			display: flex;
		}
	}
}

.c-detail-banner__image {

	@include critical {
		position: relative;

		@include mq(0, wide) {
			@include ratio(375, 265);

			@include mq(desktop) {
				@include ratio(375, 165);
			}
		}

		@include mq(wide) {
			width: calc(100% - #{$detailBannerContentWidth});
			min-height: 500px;
		}

		@include mq(widest) {
			width: calc(100% - #{$detailBannerContentWidthLarge});
			min-height: 665px;
		}
	}
}

.c-detail-banner__image__element {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.c-detail-banner__content {

	@include critical {
		padding: rem(20px) 0 rem(30px);
		text-align: center;

		@include mq(desktop) {
			padding: rem(40px) 0 rem(40px);
		}

		@include mq(wide) {
			width: $detailBannerContentWidth;
			padding: 0 0 spacing(medium) 0;
			text-align: left;
		}

		@include mq(widest) {
			width: $detailBannerContentWidthLarge;
		}
	}
}

.c-detail-banner__content__inner {

	@include critical {
		background: color(white);

		@include mq(wide) {
			padding: rem(50px) rem(60px);
			box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
		}
	}
}

.c-detail-banner__content__inner__inner {

	@include critical {

		@include mq(desktop, wide) {
			display: flex;
			text-align: left;
			align-items: flex-start;
		}

		@include mq(wide) {
			max-width: 450px;
		}
	}
}

.c-detail-banner__content__copy {

	@include critical {

		@include mq(desktop, wide) {
			padding-left: rem(40px);
		}
	}
}

.c-detail-banner__image-one {

	@include critical {
		max-width: rem(235px);
		width: 100%;
		display: block;
		margin: 0 auto rem(20px);

		@include mq(desktop) {
			margin: 0;
		}

		@include mq(wide) {
			margin: 0 0 rem(50px);
		}
	}
}

.c-detail-banner__image-two {

	@include critical {
		margin: rem(20px) auto 0;

		@include mq(wide) {
			margin: rem(50px) 0 0 0;
		}
	}
}

.c-detail-banner__title {

	@include critical {
		margin: 0 0 rem(20px) 0;
		@include font-bold();
	}
}

.c-detail-banner__action {

	@include critical {
		margin: rem(25px) 0 0 0;

		@include mq(desktop) {
			margin: rem(30px) 0 0 0;
		}
	}
}
