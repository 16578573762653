﻿.c-split-image-block__text-col {
	@include critical {
		@include mq(widest) {
			&.c-split-image-block__text-col--left {
				padding: 0 rem(60px) 0 rem(90px);
			}

			&.c-split-image-block__text-col--right {
				padding: 0 rem(90px) 0 rem(60px);
			}
		}
	}
}


.c-split-image-block__image-col {
	@include critical {
		background: url(../images/hexagons-bg-mobile.png) 0 20px repeat-x;
		width: auto;

		@include mq(0, desktop) {
			padding-bottom: rem(30px);
			width: calc(100% + 15px);
			padding-left: rem(30px);
			margin-right: rem(-15px);
		}

		@include mq(tablet, desktop) {
			margin-right: rem(-60px);
			width: calc(100% + 60px);
		}

		@include mq(desktop, wide) {
			&.c-split-image-block__image-col--left {
				margin-left: rem(-60px);
				width: calc(100% + 60px);
			}

			&.c-split-image-block__image-col--right {
				margin-right: rem(-60px);
				width: calc(100% + 60px);
			}
		}

		@include mq(desktop) {
			margin-right: 0;
			padding-bottom: rem(40px);

			&.c-split-image-block__image-col--left {
				padding-right: rem(40px);
				padding-left: 0;
			}

			&.c-split-image-block__image-col--right {
				padding-left: rem(40px);
			}

			width: 100%;
			background-image: url(../images/hexagons-bg.png);
			background-position: 0 40px;
		}

		@include mq(wide) {
			padding-left: rem(80px);
			background-position: 0 60px;
		}
	}
}

.c-split-image-block__image {
	@include critical {
		display: block;
		width: 100%;
		max-width: 100%;
	}
}
