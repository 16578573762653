﻿$compBracketTeamHeight: 50px;
$compBracketTeamMargin: 2px;
$compBracketItemMargin: 30px;
$compBracketColumnGap: 38px;

.c-comp-bracket__wrapper {

	@include critical {
		overflow: auto;
	}
}

.c-comp-bracket__wrapper__inner {

	@include critical {
		min-width: 900px;
		overflow: hidden;
	}
}

.c-comp-bracket__grid {

	@include critical {
		display: flex;
		margin: 0 -#{$compBracketColumnGap};
	}
}

.c-comp-bracket__col {

	@include critical {
		width: 33.333333333%;
		padding: 0 $compBracketColumnGap;
	}

	.c-comp-bracket--tree-layout &:not(:first-child) { // semis AND final
		.c-comp-bracket__col__list__item:not(.c-comp-bracket__col__list__item--playoff) {

			@include defer {
				position: relative;

				&:before {
					content: '';
					display: block;
					height: 2px;
					background: color(lighter-blue);
					width: 30px;
					position: absolute;
					top: calc(50% - 1px);
					left: -#{($compBracketColumnGap * 2) - 16px - 18px}; // 16px from edge in designs + width of :after
				}
			}
		}
	}

	.c-comp-bracket--tree-layout &:not(:first-child):not(:last-child) { // semis
		.c-comp-bracket__col__list {

			@include critical {
				padding-top: ($compBracketTeamHeight * 1.33) + $compBracketTeamMargin;
			}
		}

		.c-comp-bracket__col__list__item {

			@include defer {

				&:after {
					content: '';
					display: block;
					width: 18px;
					height: ($compBracketTeamHeight * 2) + $compBracketItemMargin + ($compBracketTeamMargin * 2);
					position: absolute;
					top: -#{($compBracketTeamHeight * 0.33) + $compBracketTeamMargin};
					left: -#{($compBracketColumnGap * 2) - 16px}; // 16px from edge in designs
					border: 2px solid color(lighter-blue);
					border-width: 2px 2px 2px 0;
				}
			}

			&:not(:first-child) {

				@include critical {
					margin-top: ($compBracketTeamHeight * 2.66) + $compBracketItemMargin;
				}
			}
		}
	}

	.c-comp-bracket--tree-layout &:last-child { // final
		.c-comp-bracket__col__list {

			@include critical {
				padding-top: ($compBracketTeamHeight * 4);
			}

			.c-comp-bracket__col__list__item {

				&:not(:first-child) {

					@include critical {
						margin-top: ($compBracketTeamHeight * 1.33) + $compBracketItemMargin;
					}
				}
			}

			.c-comp-bracket__col__list__item:not(.c-comp-bracket__col__list__item--playoff) {

				@include defer {

					&:after {
						content: '';
						display: block;
						width: 18px;
						height: ($compBracketTeamHeight * 4.66) + $compBracketItemMargin + ($compBracketTeamMargin * 2);
						position: absolute;
						top: -#{($compBracketTeamHeight * 1.66)};
						left: -#{($compBracketColumnGap * 2) - 16px}; // 16px from edge in designs
						border: 2px solid color(lighter-blue);
						border-width: 2px 2px 2px 0;
					}
				}
			}

			.c-comp-bracket__col__list__item--playoff {

				@include defer {
					position: relative;

					&:after {
						content: '';
						display: block;
						width: 30px;
						height: ($compBracketTeamHeight * 1.33) + $compBracketTeamMargin;
						position: absolute;
						top: -#{($compBracketTeamHeight * 0.33)};
						left: -#{($compBracketColumnGap * 2) - 16px - 18px + 2px}; // 16px from edge in designs + width + 2px (less sure on the calc for this one, looks good though)
						border: 2px dashed color(lighter-blue);
						border-width: 0 0 2px 2px;
					}
				}
			}
		}

		// final and 2nd col
		&:nth-child(2) .c-comp-bracket__col__list__item:not(.c-comp-bracket__col__list__item--playoff) { 
			@include defer {
				&:after {
					content: '';
					display: block;
					width: 18px;
					height: ($compBracketTeamHeight * 4.66) + $compBracketItemMargin + ($compBracketTeamMargin * 2);
					position: absolute;
					top: -#{($compBracketTeamHeight * 0.33) + $compBracketTeamMargin};
					left: -#{($compBracketColumnGap * 2) - 16px}; // 16px from edge in designs
					border: 2px solid color(lighter-blue);
					border-width: 2px 2px 2px 0;
					border-bottom: 0;
				}
			}
		}

		// final in 2nd col with no playoff
		&:nth-child(2) {
			.c-comp-bracket__col__list {
				@include critical {
					padding-top: ($compBracketTeamHeight * 1.33) + $compBracketTeamMargin;
				}
			}

			.c-comp-bracket__col__list__item:only-child:after {
				@include defer {
					content: '';
					display: block;
					width: 18px;
					height: ($compBracketTeamHeight * 2) + $compBracketItemMargin + ($compBracketTeamMargin * 2);
					position: absolute;
					top: -#{($compBracketTeamHeight * 0.33) + $compBracketTeamMargin};
					left: -#{($compBracketColumnGap * 2) - 16px}; // 16px from edge in designs
					border: 2px solid color(lighter-blue);
					border-width: 2px 2px 2px 0;
				}
			}
		}
	}
}

.c-comp-bracket__title {

	@include critical {
		margin: 0 0 10px 0;
		padding: 0 0 0 45px;
		@include font-bold();
		text-transform: uppercase;
		position: relative;
	}
}

.c-comp-bracket__title__icon {

	@include critical {
		position: absolute;
		top: 50%;
		left: 0;
		height: 28px;
		transform: translateY(-50%);
	}
}

.c-comp-bracket__title__icon--trophy {

	@include critical {
		width: 27px;
	}
}

.c-comp-bracket__title__icon--shield {

	@include critical {
		width: 21px;
	}
}

.c-comp-bracket__col__title {

	@include critical {
		position: relative;
		padding: 0 45px spacing(small);
		margin: 0 0 spacing(default) 0;
		@include font-default();

		&:before {
			content: '';
			display: block;
			background: color(lighter-blue);
			position: absolute;
			bottom: 0;
			height: 3px;
			left: -38px;
			right: -38px;
		}
	}
}

.c-comp-bracket__col__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.c-comp-bracket__col__list__item {

	@include critical {

		&:not(:first-child) {
			margin: $compBracketItemMargin 0 0 0;
		}
	}
}

.c-comp-bracket__col__list__team {

	@include critical {
		height: $compBracketTeamHeight;
		padding: 0 spacing(default) 0 spacing(small);
		background: color(navy);
		background: linear-gradient(rgba(0, 51, 153, 0.76) 0%, rgba(0, 44, 132, 0.79) 21.18%, #001a4d 100%);
		color: color(white);
		border-radius: 6px;
		position: relative;
		display: flex;
		align-items: center;
		font-size: rem(17px);
		line-height: rem(17px);
		@include font-bold();

		img {
			margin: 0 10px;
			display: block;
		}

		&:not(:first-child) {
			margin: $compBracketTeamMargin 0 0 0;
		}

		&.c-comp-bracket__col__list__team--loser {
			color: rgba(color(white), 0.8);
		}
	}
}

.c-comp-bracket__col__list__team--winner {

	@include critical {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		color: color(white);

		&:before {
			content: '';
			display: block;
			width: 12px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			background: color(yellow);
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;

			.c-comp-bracket__col__list__item--playoff & {
				background: color(light-blue);
			}
		}
	}
}

.c-comp-bracket__col__list__team__score {

	@include critical {
		margin: 0 0 0 auto;
		display: inline-block;
		position: relative;
		padding: 0 0 0 30px;

		.c-comp-bracket__col__list__team--unplayed & {
			display: none;
		}
	}
}

.c-comp-bracket__col__list__team__score__icon {
	
	@include critical {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: 24px;
	}
}

.c-comp-bracket__col__list__team__score__icon--trophy {

	@include critical {
		color: color(yellow);
		width: 23px;
	}
}

.c-comp-bracket__col__list__team__score__icon--shield {

	@include critical {
		color: color(light-blue);
		width: 18px;
	}
}

.c-comp-bracket__col__list__team__standing {

	@include critical {
		font-size: rem(14px);
		line-height: rem(14px);
	}
}

.c-comp-bracket__col__list__team__name {

	@include critical {
		max-width: calc(100% - 130px);
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.c-comp-bracket__col__list__team--unplayed & {
			max-width: 100%;
		}
	}
}

