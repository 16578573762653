﻿.c-partners {

	@include critical {
		padding: spacing(medium) 0 spacing(default) 0;

		@include mq(wide) {
			padding: spacing(large) 0 0 0;
		}
	}
}

.c-partners__title {

	@include critical {
		text-align: center;

		@include mq(wide) {
			margin: 0 0 -#{spacing(small)} 0;
		}
	}
}
