﻿$colWidth: rem(480px);
$colWidthWide: rem(483px);

.c-cols {

	@include critical {

		@include mq(wider) {
			display: flex;
		}
	}
}

.c-cols__primary {

	@include critical {

		@include mq(wider) {
			width: calc(100% - #{$colWidth});
		}

		@include mq(widest) {
			width: calc(100% - #{$colWidthWide});
		}
	}
}

.c-cols__secondary {

	@include critical {
		padding: rem(50px) 0 0 0;

		@include mq(wider) {
			width: $colWidth;
			padding: 0 0 0 rem(30px);
		}

		@include mq(widest) {
			width: $colWidthWide;
		}
	}
}

.c-cols__secondary--constrain {

	@include mq(0, wider) {

		.c-cols__secondary__inner {
			max-width: $colWidthWide;
			width: 100%;
			margin: 0 auto;
		}
	}
}

.c-cols__secondary--pull {

	@include mq(0, wider) {
		margin-left: -#{spacing(small)};
		margin-right: -#{spacing(small)};

		@include mq(tablet) {
			margin-left: -#{spacing(large)};
			margin-right: -#{spacing(large)};
		}
	}
}