﻿.c-header {

	@include critical {
		@include mq(0, wide) {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 101;

			.mobile-nav-open & {
				bottom: 0;
				background-color: color(yellow);
			}
		}
	}
}
@include critical {
	@include mq(0, wide) {
		body {
			padding-top: $headerHeightMobile;
		}
	}
}

.c-header__inner {

	@include critical {
		background: color(navy);

		@include mq(0, wide) {
			.mobile-nav-open & {
				background-image: url(../images/mobile-nav-bg.png);
				background-position: 100% 100%;
				background-repeat: no-repeat;
				background-color: color(yellow);
			}
		}
	}
}
.c-header__inner__inner {
	@include critical {
		@include mq(wide, widest) {
			padding-left: 0;
		}
	}
}

.c-header__inner__inner__inner {

	@include critical {
		position: relative;
		height: 60px;

		@include mq(0, wide) {
			.mobile-nav-open & {
				height: 100vh;
			}
		}

		@include mq(wide) {
			padding: 0 0 0 120px;
			height: auto;
		}

		@include mq(widest) {
			padding: 0 0 0 135px; // 160 - left padding on nav item
		}
	}
}

.c-header__logo {

	@include critical {
		width: 60px;
		position: absolute;
		left: 0;
		top: 10px;
		z-index: 4; // lift above embedded videos

		@include mq(wide) {
			width: 110px;
			top: spacing(default);
		}
	}
}

.c-header__nav {
	@include critical {
		display: none;

		&[aria-expanded=true] {
			display: block;
			padding-top: 0;

			@include mq(wide) {
				padding-top: rem(40px);
				position: absolute;
				top: 60px;
				left: 0;
				right: 0;
				bottom: 50px;
			}
		}

		@include mq(wide) {
			display: block;
			padding-top: 0;
		}
	}
}

.c-header__nav-toggle {

	@include critical {
		display: block;
		cursor: pointer;
		padding: 0;
		margin: 0;
		border-radius: 0;
		border: 0;
		vertical-align: top;
		@include appearance-none();
		background: color(navy);
		color: color(white);
		height: 60px;
		width: 50px;
		position: absolute;
		top: 0;
		bottom: 0;
		right: -12.5px;

		.c-header__nav-toggle-text {
			display: none;
			color: color(navy);
			font-size: rem(13px);
			margin-left: -90px;

			@include mq(0, wide) {
				.mobile-nav-open & {
					position: relative;
					display: inline-block;
				}
			}
		}

		&:before,
		&:after,
		.c-header__nav-toggle__inner:before {
			content: '';
			display: block;
			width: 21px;
			height: 2.5px;
			background: color(white);
			position: absolute;
			top: 50%;
			left: 50%;
			transform-origin: 0 50%;
			transform: translate3d(-50%, -50%, 0);

			@include mq(0, wide) {
				.mobile-nav-open & {
					background: color(navy);
				}
			}
		}

		&:after {
			width: 25px;
			margin-top: -10px;
		}

		.c-header__nav-toggle__inner:before {
			margin-top: 10px;
			width: 14.5px;
		}

		@include mq(wide) {
			display: none;
		}

		@include mq(0, wide) {
			.mobile-nav-open & {
				background: color(yellow);

				&:before {
					opacity: 0;
				}

				&:after {
					width: 28px;
					transform: translateX(-50%) rotate(45deg);
				}

				.c-header__nav-toggle__inner:before {
					width: 28px;
					transform: translateX(-50%) rotate(-45deg);
				}
			}
		}
	}

	@include defer {
		@include focus();
	}
}

.c-header__nav-toggle__inner {

	@include critical {
		display: block;
	}
}

.c-header__tools {

	@include critical {
		position: absolute;
		top: 0;
		bottom: 0;
		left: calc(50% - 70px); /* Half the width of the search bar*/

		@include mq(wide) {
			width: auto;
			left: auto;
			display: flex;
			align-items: center;
			right: -#{spacing(large)}; // pull out of constrain
		}

		@include mq(widest) {
			padding-right: spacing(small);
		}

		.mobile-nav-open & {

			@include mq(0, wide) {
				margin-top: auto;
				position: static;
				flex-shrink: 0;
				top: auto;
				bottom: auto;
				left: auto;
				right: auto;
			}
		}
	}
}

.c-header__sso {

	@include critical {
		display: none;

		@include mq(wide) {
			display: block;
		}

		@include mq(widest) {
			padding-left: spacing(small);
			font-size: 0;
			line-height: 0;
		}

		@include mq(wide, widest) {
			height: 100%;
			position: relative;
		}

		.c-button {
			vertical-align: middle;

			&:not(:first-child) {
				margin: 0 0 0 spacing(small);

				@include mq(wide, widest) {
					margin: rem(25px) 0 0 0;
				}
			}
		}
	}

	@include defer {

		@include mq(0, widest) {

			.c-button {
				border-color: color(navy);
				color: color(navy);

				.html--can-hover &:hover {
					background: color(navy);
					color: color(white);
					border-color: color(navy);
				}
			}
		}

		@include mq(wide, widest) {

			.c-button {
				width: 100%;
				min-width: 200px;
			}
		}

		.mobile-nav-open & {

			@include mq(0, wide) {
				display: block;
				text-align: center;
				padding: spacing(small) 0 5px 0;

				.c-button {
					min-width: 30%;
				}
			}
		}
	}
}

	.c-header__sso__trigger {

		@include critical {
			display: none;
			width: 75px;
			height: 100%;
			border: 0;
			margin: 0;
			background: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border-radius: 0;
			position: relative;
			color: color(white);
			cursor: pointer;
			@include focus();

			@include mq(wide, widest) {
				display: block;
			}
		}

		@include defer {

			@include mq(wide, widest) {

				&[aria-expanded="true"].is-loaded {
					background: color(yellow);
					color: color(navy);
				}
			}
		}
	}

	.c-header__sso__trigger__icon {

		@include critical {
			width: rem(30px);
			height: rem(30px);
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -15px 0 0 -15px;
		}
	}

	.c-header__sso__menu {

		@include critical {

			@include mq(wide, widest) {
				display: none;
				text-align: center;
				position: absolute;
				top: 100%;
				right: 0;
				background: color(yellow);
				padding: rem(30px);
				z-index: 99;

				&.is-open.is-loaded {
					display: block;
				}
			}
		}
	}

	.c-header__search {

		@include critical {
			display: flex;
			height: 100%;

			@include mq(wide) {
				border-left: 1px solid #384562;
				border-right: 1px solid #384562;
			}

			.mobile-nav-open & {

				@include mq(0, wide) {
					position: static;
					border: 2px solid color(navy);
					height: rem(54px);
				}
			}
		}
	}

	.c-header__search__text {

		@include critical {
			@include appearance-none();
			background: color(navy);
			color: color(white);
			@include font-medium();
			font-size: rem(11px);
			line-height: normal;
			padding: 0;
			margin: 0;
			border-radius: 0;
			height: 100%;
			border: 0;
			width: 140px;
			text-transform: none;

			@include mq(0, wide) {
				.mobile-nav-open & {
					background: color(yellow);
					color: color(navy);
					padding-left: rem(15px);
					padding-right: rem(15px);
					width: 100%;
					height: rem(50px);
					font-size: rem(16px);

					@include placeholder() {
						color: color(navy);
						opacity: 1;
					}
				}
			}

			@include mq(wide) {
				display: none;
			}

			@include mq(1300px) {
				padding: 0 0 0 25px;
				font-size: rem(13px);
				display: block;
				width: 200px;
			}

			@include placeholder() {
				color: color(white);
				opacity: 1;
			}
		}

		@include defer {

			&::-ms-clear {
				display: none;
			}

			&:focus {
				outline: none;

				@include mq(wide) {
					background: color(white);
					color: color(navy);

					@include placeholder() {
						color: color(navy);
					}

					+ .c-header__search__button {
						background: color(white);
						color: color(navy);

						@include hover() {
							color: color(royal-blue);
						}
					}
				}
			}
		}
	}

	.c-header__search__button {

		@include critical {
			cursor: pointer;
			display: inline-block;
			padding: 0;
			margin: 0;
			border-radius: 0;
			border: 0;
			vertical-align: top;
			@include appearance-none();
			background: color(navy);
			color: color(white);
			height: 100%;
			width: 50px;
			position: relative;

			@include mq(0, wide) {
				.mobile-nav-open & {
					background: color(yellow);
					color: color(navy);
				}
			}

			@include mq(wide) {
				width: 75px;
			}
		}

		@include defer {
			@include focus();

			@include hover() {
				color: color(yellow);
			}
		}
	}

	.c-header__search__button__icon {

		@include critical {
			width: 24px;
			height: 24px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -12px 0 0 -12px;

			@include mq(wide) {
				width: 22px;
				height: 22px;
				margin: -11px 0 0 -11px;
			}
		}
	}

	.c-header__language {

		@include critical {
			display: none;
			position: absolute;
			top: 0;
			right: -#{spacing(large)}; // pull out of "constrain" container
			height: 100%;
			width: 90px;
			border-right: 1px solid #384562;

			@include mq(wide) {
				display: block;
			}
		}

		@include defer {

			.mobile-nav-open & {

				@include mq(0, wide) {
					display: block;
					position: static;
					width: 100%;
					height: rem(79px);
					padding: rem(15px) 0 rem(10px);
					border: 0;
				}
			}
		}
	}

	.c-header__language__select.c-select {

		@include critical {
			height: 100%;
			padding-top: 0;
			padding-bottom: 0;
			border: 0;

			&::-ms-value {
				color: color(white);
			}
		}

		@include defer {

			&:focus {
				background: color(white);
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNiAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiMwMDE0M2IiIGQ9Ik0yMy4zMDMtLjAwMkwxMi40NjcgMTAuODM0IDEuNjMtLjAwMi0uNDU0IDIuMDgybDEyLjkyMSAxMi45MiAyLjA4NC0yLjA4NEwyNS4zODcgMi4wODJ6Ii8+Cjwvc3ZnPgo=");
				color: color(navy);

				&::-ms-value {
					color: color(navy);
				}
			}

			.mobile-nav-open & {

				@include mq(0, wide) {
					background: color(yellow);
					color: color(navy);
					border: 2px solid color(navy);
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNiAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiMwMDE0M2IiIGQ9Ik0yMy4zMDMtLjAwMkwxMi40NjcgMTAuODM0IDEuNjMtLjAwMi0uNDU0IDIuMDgybDEyLjkyMSAxMi45MiAyLjA4NC0yLjA4NEwyNS4zODcgMi4wODJ6Ii8+Cjwvc3ZnPgo=");

					&::-ms-value {
						color: color(navy);
					}
				}
			}
		}
	}

	@include critical {

		.c-header__ticker {
			background: color(yellow);
			display: none;

			@include mq(wide) {
				display: block;
			}
		}

		.c-header__ticker__inner__inner {
			display: flex;
			align-items: center;
			padding: spacing(tiny) 0 spacing(tiny) 160px;
			white-space: nowrap;
		}

		.c-header__ticker-links {
			margin-left: auto;
		}

		.c-header__ticker-link-highlight {
			text-transform: uppercase;
			text-decoration: underline;
			margin-left: rem(20px);
			@include font-bold();
		}

		.c-header__ticker-heading {
			text-transform: uppercase;
			margin-right: rem(25px);
		}

		.c-header__ticker-copy {
			margin-right: rem(10px);
			@include font-medium();
			max-width: 50%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.c-header__ticker-cta {
			text-decoration: none;
			@include font-bold();
		}

		.c-header__ticker--mobile {
			padding: rem(spacing(default)) 0;

			@include mq(wide) {
				display: none;
			}
		}

		.c-header__ticker-mobile-buttons {
			@include mq(0, wide) {
				display: flex;

				.c-button {
					flex: 1;
					padding-left: rem(10px);
					padding-right: rem(10px);
				}

				.c-button:first-child {
					margin-right: rem(5px);
				}

				.c-button:last-child {
					margin-right: rem(5px);
				}
			}
		}
	}

	@include defer {

		.c-header__ticker-cta {
			@include hover() {
				text-decoration: underline;
			}
		}
	}

	.c-header__social {

		@include critical {
			display: none;
		}

		@include defer {

			@include mq(0, wide) {

				.c-social__title {
					display: none;
				}

				.c-social__item {
					width: rem(60px);
				}

				.mobile-nav-open & {
					display: block;
					margin-bottom: rem(20px);
				}
			}
		}
	}

	.c-header__mobile-nav-wrapper {
		@include defer {
			@include mq(0, wide) {
				.mobile-nav-open & {
					display: flex;
					flex-direction: column;
					overflow: auto;
					height: 100%;
				}
			}
		}
	}

	.c-header__mobile-nav-wrapper {
		@include defer {
			@include mq(0, wide) {
				.c-nav {
					padding-top: rem(90px);
					padding-bottom: rem(20px);
				}
			}
		}
	}