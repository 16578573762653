.c-match-detail {

	@include critical {
		background: linear-gradient(color(white), #edf0f2);
		padding: rem(30px) 0;

		@include mq(desktop) {
			padding: rem(40px) 0;
		}
	}
}

.c-match-detail__inner {

	@include critical {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include mq(0, wide) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}

.c-match-detail__details {

	@include critical {
		text-align: center;
		padding: 0 rem(30px);

		@include mq(0, wide) {
			padding: rem(30px) 0;
		}
	}
}

.c-match-detail__action {

	@include critical {
		padding: rem(25px) 0 0 0;
	}
}

.c-match-detail__score,
.c-match-detail__details,
.c-match-detail__social {

	@include critical {

		@include mq(0, wide) {
			flex-basis: 100%;
		}
	}
}

.c-match-detail__details__line {

	@include critical {
		margin: 0;
	}
}

.c-match-detail__details__line--title {

	@include critical {
		margin: 0;
		@include font-bold();
		font-size: rem(16px);
		line-height: rem(22px);
	}
}

.c-match-detail__details__copy {

	@include critical {
		margin: 25px 0 0 0;
	}
}

.c-match-detail__details__copy__more {

	@include critical {
		background: none;
		margin: 0;
		padding: 0 20px 0 0;
		border: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		cursor: pointer;
		color: color(light-blue);
		@include font-bold();
		position: relative;
		display: inline-block;

		.no-js & {
			display: none;
		}
	}

	@include defer {

		&:before,
		&:after {
			content: '';
			display: block;
			width: 12px;
			height: 2px;
			background: color(light-blue);
			position: absolute;
			right: 0;
			top: 50%;
			margin: -1px 0 0 0;
		}

		&:after {
			transform: rotate(90deg);
		}

		&[aria-expanded="true"]:after {
			display: none;
		}
	}
}

.c-match-detail__details__copy__inner {

	@include critical {

		.js & {
			height: 0;
			transition: height 0.5s ease;
			overflow: hidden;
		}
	}
}

.c-match-detail__details__copy__inner__inner {

	@include critical {

		.js & {
			padding: 25px 0 0 0;
		}
	}
}

.c-match-detail__image--with-social {

	@include critical {

		.c-social {
			padding: spacing(default) 0 0 0;
		}

		@include mq(wide) {
			position: relative;
			padding: 0 62px 0 0;

			.c-social {
				position: absolute;
				top: 50%;
				right: 0;
				padding: 0;
				transform: translateY(-50%);
			}

			.c-social__list {
				display: block;
				margin: 0;
			}

			.c-social__item {
				padding: 0;

				&:not(:first-child) {
					margin: 6px 0 0 0;
				}
			}

			.c-share__link {
				width: 37px;
				height: 37px;
			}
		}
	}
}

.c-match-detail__image__inner {

	@include critical {

		.c-sponsor {

			&:not(:first-child) {
				margin: spacing(small) 0 0 0;
			}
		}
	}
}

.c-match-detail__image__item {

	@include critical {

		&:not(:first-child) {
			margin: spacing(small) 0 0 0;
		}
	}
}

.c-match-detail--align-top {

	@include critical {

		.c-match-detail__inner {
			align-items: center;
		}
	}
}

.c-match-detail--no-left-image {

	@include critical {
		
		.c-match-detail__details {

			@include mq(0, wide) {
				padding-top: 0;
			}

			@include mq(wide) {
				text-align: left;
			}
		}
	}
}
