﻿$poolsColumnGap: 15px;

.c-pools {

	@include critical {
		
	}
}

.c-pools__title {

	@include critical {
		@include font-bold();
		text-transform: uppercase;
		position: relative;
		padding: 0 0 #{spacing(small) - 3px} 0;
		margin: 0 0 #{spacing(default) - $poolsColumnGap} 0;
		border-bottom: 3px solid color(lighter-blue);
	}
}

.c-pools__wrapper {

	@include critical {
		
	}
}

.c-pools__grid {

	@include critical {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -#{$poolsColumnGap} -#{$poolsColumnGap};
		width: calc(100% + #{$poolsColumnGap * 2})
	}
}

.c-pools__col {

	@include critical {
		width: 100%;
		padding: $poolsColumnGap;

		@include mq(wide) {
			width: 50%;
		}

		.c-pools:not(.c-pools--two-up) & {

			@include mq(widest) {
				width: 33.333333333%;
			}
		}
	}
}

.c-pools__table {

	@include critical {
		width: 100%;
		border-collapse: collapse;
		text-align: left;
		border-radius: 6px;
		background: color(white);
		box-shadow: 0px 2px 5px rgba(188, 188, 188, 0.25);

		td,
		th {
			font-size: rem(11px);
			line-height: rem(15px);
			text-transform: uppercase;
			padding: spacing(small) 10px #{spacing(small) - 1px};

			@include mq(largePhone) {
				font-size: rem(12px);
				line-height: rem(16px);
			}

			@include mq(desktop) {
				font-size: rem(14px);
				line-height: rem(18px);
			}

			&.c-pools__table__name {

				@include mq(desktop) {
					font-size: rem(17px);
					line-height: rem(21px);
					@include font-bold();
				}

				width: 80px;

				@include mq(middle) {
					width: 125px;
				}

				.c-pools--three-up & {
					@include mq(desktop) {
						width: 150px;
					}
				}

				.c-pools--two-up & {
					@include mq(desktop) {
						width: auto;
					}
				}
			}
		}

		thead {

			td,
			th {
				background: linear-gradient(rgba(0, 51, 153, 0.76) 0%, rgba(0, 44, 132, 0.79) 21.18%, #001a4d 100%);
				color: color(white);
				@include font-bold();

				&:first-child {
					border-top-left-radius: 6px;
				}

				&:last-child {
					border-top-right-radius: 6px;
				}
			}
		}

		tbody {

			tr {

				&:not(:first-child) {

					td,
					th {
						border-top: 1px solid color(lighter-blue);
					}
				}

				&:first-child {

					td,
					th {
						padding-top: #{spacing(small) + 5px};
					}
				}

				&:last-child {

					td,
					th {
						padding-bottom: #{spacing(small) + 5px};

						&:first-child {
							border-bottom-left-radius: 6px;
						}

						&:last-child {
							border-bottom-right-radius: 6px;
						}
					}
				}
			}
		}
	}
}

abbr.c-pools__table__abbr {

	@include critical {
		text-decoration: none;
	}
}

.c-pools__table__name__inner {

	@include critical {
		display: flex;
		align-items: center;

		.c-pools__table:not(.c-pools__table--no-qualifiers) & {
			padding: 0 0 0 25px;
		}

		.c-pools__table__name--qualified & {
			padding: 0 !important;
		}

		img {
			margin: 0 10px 0 0;

			@include mq(0, middle) {
				display: none;
			}
		}
	}
}

.c-pools__table__name__icon {

	@include critical {
		display: block;
		height: 20px;
		margin: 0 10px 0 0;
	}
}

.c-pools__table__name__icon--shield {

	@include critical {
		width: 15px;
		color: color(light-blue);
	}
}

.c-pools__table__hide-on-small-screens {

	@include critical {

		@include mq(0, largePhone) {
			display: none;
		}
	}
}
