.c-section {

	@include critical {
		padding: spacing(medium) 0;

		@include mq(wide) {
			padding: spacing(massive) 0;
		}
	}
}

.c-section__wrapper-tight .c-section:not([class*="--"]):first-child {
	@include critical {
		padding-top: 0;
	}
}

.c-section__wrapper-tight .c-section:not([class*="--"]) + .c-section:not([class*="--"]) {
	@include critical {
		padding-top: 0;
	}
}

.c-section__inner {

	@include critical {

		@include mq(wide) {
			position: relative;
		}
	}
}

.c-section__title {

	@include critical {
		text-align: center;
		margin: 0 0 20px 0;

		@include mq(wide) {
			padding: 0 225px;
			margin: 0 0 spacing(default) 0;
		}
	}
}

.c-section__action {

	@include critical {
		margin: spacing(default) 0 0 0;
		text-align: center;

		@include mq(wide) {
			position: absolute;
			top: -2px;
			right: spacing(large);
			margin: 0;
		}
	}
}

.c-section__footer {

	@include critical {
		margin: spacing(default) 0 0 0;
	}
}

.c-section__header {

	@include critical {
		margin: 0 0 spacing(large) 0;
	}
}

.c-section__header-inner {

	@include critical {
		display: flex;
		align-items: center;
	}
}

.c-section__header-title {

	@include critical {
		margin: 0;
	}
}

.c-section__header-action {

	@include critical {
		margin: 0 0 0 auto;
		padding: 0 0 0 spacing(default);
	}
}

.c-section--hexagons {

	@include defer {
		background: url(../images/hexagons.png) 0 40px no-repeat, linear-gradient(180deg, #eef0f2 0%, color(white) 100%);
	}
}

.c-section--stars {

	@include defer {
		background: url(../images/stars.png) 0 0 no-repeat, linear-gradient(#FFFFFF, #DCE1E6);
	}
}

.c-section--gradient {

	@include defer {
		position: relative;
		background: linear-gradient(color(white), #edf0f2);

		.c-section__inner {
			position: relative;
			z-index: 2;
		}
	}
}

.c-section--gradient-reverse {

	@include defer {
		position: relative;
		background: linear-gradient(#edf0f2, color(white));

		.c-section__inner {
			position: relative;
			z-index: 2;
		}
	}
}

.c-section--gradient:not(.c-section--gradient-simple)::after {
	@include defer {
		content: '';
		background-image: linear-gradient(to left top, color(white) 0%, color(white) 50%, transparent 50%);
		position: absolute;
		width: 100%;
		height: 30px;
		bottom: 0;
		left: 0;
		pointer-events: none;

		@include mq(desktop) {
			height: 50px;
		}

		@include mq(wide) {
			height: 150px;
		}
	}
}

.c-section--slim {

	@include critical {
		padding: spacing(medium) 0;

		@include mq(wide) {
			padding: spacing(medium) 0;
		}
	}
}

.c-section__supplementary {

	@include critical {
		padding: spacing(medium) 0 0 0;

		@include mq(desktop) {
			padding: spacing(large) 0 0 0;
		}

		@include mq(wide) {
			padding: spacing(massive) 0 0 0;
		}
	}
}

.c-section--tight {

	@include critical {
		padding: 0;
	}	
}

.c-section--tight-top {

	@include critical {
		padding-top: 0;
	}
}

.c-section--gallery {

	@include critical {
		overflow: hidden;

		.c-section__inner {

			@include mq(0, desktop) {
				padding: 0;
			}
		}
	}
}

.c-section--tight-top-wide {

	@include critical {

		@include mq(wide) {
			padding-top: rem(40px);
		}
	}
}

.c-section--image-copy {

	@include critical {
		background: color(light-grey-alt);
	}

	.c-section__header {

		@include critical {

			@include mq(0, wide) {
				margin: 0 0 25px 0;
			}
		}
	}

	.c-section__header-title {

		@include mq(0, largeDesktop) {
			text-align: center;
			width: 100%;
		}
	}

	.c-section__header-action {

		@include critical {

			@include mq(0, largeDesktop) {
				display: none;
			}
		}
	}

	.c-section__footer {

		@include critical {
			text-align: center;
			margin: spacing(default) 0 0 0;

			@include mq(largeDesktop) {
				display: none;
			}
		}
	}

	.c-image-copy--White + .c-section__footer {

		@include critical {
			margin: 10px 0 0 0;

			.c-section__footer-inner {
				border-top: 1px solid #9A9A9A;
				padding: 20px 0 0 0;
			}
		}
	}
}
