﻿@include critical {
	.autocomplete__wrapper {
		position: relative
	}
}

.autocomplete__input {
	@include critical {
		background-color: transparent;
		position: relative;
		background: color(yellow);
		color: color(navy);

		@include placeholder() {
			color: color(navy);
			opacity: 1;
		}
	}

	@include defer {

		&:focus:not([aria-invalid="true"]) {
			border-color: color(navy);
		}
	}
}

@include defer {

	.autocomplete__hint {
		position: absolute;
		opacity: 0.0001;
	}


	.autocomplete__input--show-all-values {
		cursor: pointer;
	}

	.autocomplete__dropdown-arrow-down {
		z-index: -1;
		display: inline-block;
		position: absolute;
		right: 8px;
		width: 24px;
		height: 24px;
		top: 10px
	}

	.autocomplete__menu {
		background-color: #fff;
		border: 2px solid color(navy);
		border-top: 0;
		color: color(navy);
		margin: 0;
		max-height: 342px;
		overflow-x: hidden;
		padding: 0;
		width: 100%;
		text-align: left;
	}

	.autocomplete__menu::-webkit-scrollbar {
		width: 11px;
	}

	.autocomplete__menu {
		scrollbar-width: thin;
		scrollbar-color: #90A4AE color(light-grey);
	}

	.autocomplete__menu::-webkit-scrollbar-track {
		background: color(light-grey);
	}

	.autocomplete__menu::-webkit-scrollbar-thumb {
		background-color: #90A4AE;
		border-radius: 6px;
		border: 3px solid color(light-grey);
	}

	.autocomplete__menu--visible {
		display: block;
	}

	.autocomplete__menu--hidden {
		display: none;
	}

	.autocomplete__menu--overlay {
		box-shadow: rgba(0,0,0,.256863) 0 2px 6px;
		left: 0;
		position: absolute;
		top: 100%;
		z-index: 100;
	}

	.autocomplete__menu--inline {
		position: absolute;
		top: 50px; /* Height of input */
		left: 0;
		right: 0;
		max-height: 218px;
	}

	.autocomplete__option {
		border-bottom: solid color(grey);
		border-width: 1px 0;
		cursor: pointer;
		display: block;
		position: relative;
	}

	.autocomplete__option > * {
		pointer-events: none;
	}

	.autocomplete__option:first-of-type {
		border-top-width: 0;
	}

	.autocomplete__option:last-of-type {
		border-bottom-width: 0;
	}

	.autocomplete__option--odd {
		background-color: #fafafa;
	}

	.autocomplete__option--focused,
	.autocomplete__option:hover {
		background-color: color(navy);
		color: #FFF;
		outline: 0;
	}

	.autocomplete__option--no-results {
		color: color(navy);
		cursor: not-allowed;
	}

	.autocomplete__hint, .autocomplete__input, .autocomplete__option {
		font-size: rem(16px);
		line-height: 1.25;
	}

	.autocomplete__hint, .autocomplete__option {
		padding: rem(5px);
	}

	@media (min-width:641px) {
		.autocomplete__hint, .autocomplete__input, .autocomplete__option {
			font-size: rem(19px);
			line-height: 1.31579;
		}
	}
}