﻿.c-generic-list {

	@include critical {
		font-size: rem(12px);
		line-height: rem(17px);
		@include font-medium();
	}
}

.c-generic-list__title {

	@include critical {
		font-size: rem(12px);
		line-height: rem(17px);
		@include font-black();
		text-transform: uppercase;
		margin: 0 0 10px 0;
		padding: 0;
	}
}

.c-generic-list__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.c-generic-list__item {

	@include critical {

		&:not(:last-child) {
			margin: 0 0 10px 0;
		}
	}
}

.c-generic-list__link {

	@include critical {
		text-transform: uppercase;
	}
}

.c-generic-list--inline {

	@include critical {

		.c-generic-list__list {
			display: flex;
		}

		.c-generic-list__item {

			&:not(:last-child) {
				margin: 0;
				padding: 0 25px 0 0;
				position: relative;

				&:after {
					content: '';
					display: block;
					width: 1px;
					position: absolute;
					top: 4px;
					bottom: 4px;
					right: 12px;
					background: color(white);
				}
			}
		}
	}
}

.c-generic-list--small {

	@include critical {
		font-size: rem(11px);
		line-height: rem(17px);
		@include font-medium();
	}
}

.c-generic-list--footer-accordion {

	@include critical {

		.c-generic-list__toggle-button {
			display: none;
		}

		.js & {

			.c-generic-list__list {

				@include mq(0, wide) {
					height: 0;
					transition: height 0.5s ease;
					overflow: hidden;
				}

				@include mq(wide) {
					height: auto !important;
				}
			}
		}

		@include mq(0, wide) {
			.c-generic-list__title {
				padding: spacing(small);
				margin: 0;
				font-size: rem(14px);
				line-height: rem(20px);
			}

			.c-generic-list__list {
				padding: 0 spacing(default);
				font-size: rem(14px);
				line-height: rem(20px);
			}

			.c-generic-list__item {
				padding: 7.5px 0;
				margin: 0;

				&:last-child {
					padding-bottom: spacing(default);
				}
			}
		}
	}

	@include defer {

		@include mq(0, wide) {

			.c-generic-list__toggle-button {
				@include focus(); // has to be outside of .js selector
			}

			.js & {

				.c-generic-list__title {
					position: relative;
					padding-right: 35px;
				}

				.c-generic-list__toggle-button {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: transparent;
					border: 0;
					margin: 0;
					padding: 0;
					@include appearance-none();

					&:after {
						content: '';
						display: block;
						width: 10px;
						height: 10px;
						background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNiAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik0yMy4zMDMtLjAwMkwxMi40NjcgMTAuODM0IDEuNjMtLjAwMi0uNDU0IDIuMDgybDEyLjkyMSAxMi45MiAyLjA4NC0yLjA4NEwyNS4zODcgMi4wODJ6Ii8+Cjwvc3ZnPgo=");
						background-position: 0 0;
						background-repeat: no-repeat;
						background-size: 10px 10px;
						position: absolute;
						top: 50%;
						right: 15px;
						margin: -5px 0 0 0;
						transition: transform 0.5s ease;
					}
				}

				.c-generic-list__toggle-button[aria-expanded="true"] {

					&:after {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
}