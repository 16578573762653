﻿$donutStatMaxStatsBelow: 375px;

.c-donut-stat__inner {

	@include critical {
		@include ratio(1,1);
		border-radius: 50%;
		overflow: hidden;
		position: relative;
	}
}

.c-donut-stat__slice {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.c-donut-stat__slice--home {

	@include critical {
		color: color(yellow);
		transform: rotate(270deg) scaleY(-1);
	}
}

.c-donut-stat__slice--away {

	@include critical {
		color: color(navy);
		transform: rotate(270deg);
	}
}

.c-donut-stat__slice__svg {

	@include critical {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: none;

		&.c-donut-stat__slice__svg--leads { // when the scores are equal, both teams "lead"
			display: block;
		}
	}
}

.c-donut-stat__slice__svg--behind {

	@include critical {
		width: 94%;
		height: 94%;
	}
}

.c-donut-stat__text {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.c-donut-stat__text__inner {

	@include critical {
		width: 55%;
		text-align: center;
		@include font-medium();
		font-size: rem(10px);
		line-height: rem(12px);

		@include mq(largePhone) {
			font-size: rem(13px);
			line-height: rem(15px);
		}

		@include mq(desktop) {
			font-size: rem(16px);
			line-height: rem(18px);
			padding: 0 rem(5px);
		}

		@include mq(wide) {
			font-size: rem(18px);
			line-height: rem(20px);
		}
	}
}

.c-donut-stat__text__inner__description {

	@include critical {
		display: block;
		padding: rem(5px) 0 rem(7px);
		@include font-default();

		@include mq(wide) {
			padding: rem(10px) 0 rem(12px);
		}

		@include mq(0, $donutStatMaxStatsBelow) {
			padding: 0;
		}
	}
}

.c-donut-stat__text__inner__stat {

	@include critical {
		display: block;
		color: color(navy);

		@include mq(0, $donutStatMaxStatsBelow) {
			display: none;
		}
	}
}


.c-donut-stat__data {

	@include mq($donutStatMaxStatsBelow + 1) {
		@include hide();
	}

	@include mq(0, $donutStatMaxStatsBelow) {
		text-align: center;
		padding: rem(5px) 0 0 0;
		color: color(navy);
	}
}

.c-donut-stat--home-leads {

	@include critical {

		.c-donut-stat__slice--home {

			.c-donut-stat__slice__svg--behind {
				display: none;
			}

			.c-donut-stat__slice__svg--leads {
				display: block;
			}
		}

		.c-donut-stat__slice--away {

			.c-donut-stat__slice__svg--behind {
				display: block;
			}

			.c-donut-stat__slice__svg--leads {
				display: none;
			}
		}
	}
}

.c-donut-stat--away-leads {

	@include critical {

		.c-donut-stat__slice--home {

			.c-donut-stat__slice__svg--behind {
				display: block;
			}

			.c-donut-stat__slice__svg--leads {
				display: none;
			}
		}

		.c-donut-stat__slice--away {

			.c-donut-stat__slice__svg--behind {
				display: none;
			}

			.c-donut-stat__slice__svg--leads {
				display: block;
			}
		}
	}
}