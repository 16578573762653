﻿.c-pod {

	@include critical {
		background: color(white);
		padding: rem(40px) rem(20px);
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
	}
}

.c-pod__title {

	@include critical {
		margin: 0 0 rem(20px) 0;
		font-size: rem(20px);
		line-height: rem(24px);
		@include font-bold();
		text-transform: uppercase;
		text-align: center;
	}
}

.c-pod__action {

	@include critical {
		margin: rem(30px) 0 0 0;
		text-align: center;
	}
}