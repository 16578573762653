﻿@include critical {

	.c-table {
		border-collapse: collapse;
		width: 100%;
	}

	.c-table td,
	.c-table th {
		border: 1px solid color(grey);
		padding: rem(10px);
		text-align: left;
	}

	.c-table tr:nth-child(even) {
		background-color: #eeeeee;
	}

	.c-table--even-split {

		td,
		th {
			width: 50%;
		}
	}

	.c-table--emphasize {
		font-size: rem(20px);
		line-height: rem(26px);

		@include mq(0, desktop) {
			font-size: rem(16px);
			line-height: rem(22px);
		}

		thead {

			&.c-table__thead--large {

				tr:first-child {

					td,
					th {
						font-size: rem(26px);
						line-height: rem(32px);

						@include mq(0, desktop) {
							font-size: rem(16px);
							line-height: rem(22px);
						}
					}
				}
			}

			tr:first-child {

				td,
				th {
					padding-top: 0;
				}
			}
		}

		th {
			@include font-bold();
			text-transform: uppercase;
		}

		td,
		th {
			border: 0;
			padding: rem(15px) rem(12px);

			@include mq(0, desktop) {
				padding: rem(10px) rem(6px);
			}

			&:first-child {
				padding-left: 24px;

				@include mq(0, desktop) {
					padding-left: 12px;
				}
			}

			&:last-child {
				padding-right: 24px;

				@include mq(0, desktop) {
					padding-right: 12px;
				}
			}
		}

		tr:nth-child(even) {
			background-color: transparent;
		}

		tr:last-child {

			td,
			th {
				border-bottom: 1px solid color(black-squeeze);
			}
		}

		tbody tr:nth-child(odd) {
			background-color: color(black-squeeze);
		}

		& + .c-table--emphasize {
			margin-top: rem(50px);
		}
	}

	.c-table__count {

		@include mq(desktop) {
			display: flex;
		}
	}

	.c-table__count__number {
		color: color(royal-blue);
		@include font-black();
		font-size: rem(16px);

		@include mq(desktop) {
			width: 45px;
			display: block;
		}
	}

	.c-table__count__text {

		@include mq(desktop) {
			display: block;
		}
	}
}

abbr.c-table__abbr {

	@include critical {
		text-decoration: none;
	}
}
