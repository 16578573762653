﻿@include critical {

	.c-search-form {
		display: flex;
		max-width: 500px;
		margin: 0 auto;
	}

	.c-search-form__button {
		display: flex;
		align-items: center;
		white-space: nowrap;
		margin-left: rem(10px);
		padding-left: rem(15px);
		padding-right: rem(15px);
	}

	.c-search-form__icon {
		margin-left: rem(5px);
	}

	.c-search-form__container {
		background-color: color(yellow);
	}

	.c-search-form__result-text {
		text-align: center;
		margin: rem(spacing(small)) 0 0 0;
	}
}
