﻿.c-gallery {

	@include critical {

		.tns-visually-hidden {
			@include hide();
		}

		.tns-outer {
			overflow-x: hidden;
		}

		.tns-controls {
			display: none;
		}
	}

	@include defer {

		.tns-controls {
			display: block;

			[data-controls] {
				display: block;
				position: absolute;
				top: 50%;
				right: -#{rem(30px)};
				width: rem(60px);
				height: rem(190px);
				background: color(yellow);
				border: 0;
				margin: -#{rem(95px)} 0 0 0;
				padding: 0;
				@include appearance-none();
				border-radius: 0;
				cursor: pointer;
				@include focus();
				font-size: 0;
				line-height: 0;
				text-indent: -#{rem(90000px)};
				text-align: left;
				opacity: 1;
				transition: opacity 0.25s ease, background 0.25s ease;

				@include hover {
					background: color(navy);

					&:before,
					&:after {
						background: color(white);
					}
				}

				@include mq(0, desktop) {
					right: 0;

					&:not(:focus) {
						@include hide();
					}
				}

				@include mq(1650px) {
					right: -#{rem(80px)};
				}

				&:before,
				&:after {
					content: '';
					display: block;
					width: rem(2px);
					height: rem(28px);
					background: color(navy);
					position: absolute;
					top: 50%;
					left: 50%;
					margin: -#{rem(26px)} 0 0 0;
					transform: rotate(-30deg);
					transition: background 0.25s ease;
				}

				&:after {
					transform: rotate(30deg);
					margin: -#{rem(2px)} 0 0 0;
				}
			}

			[data-controls="prev"] {
				right: auto;
				left: -#{rem(30px)};

				@include mq(0, desktop) {
					left: 0;
				}

				@include mq(1650px) {
					left: -#{rem(80px)};
				}

				&:before {
					transform: rotate(30deg);
					margin-left: -#{rem(1px)};
				}

				&:after {
					transform: rotate(-30deg);
					margin-left: -#{rem(1px)};
				}
			}

			[disabled] {
				opacity: 0;
				cursor: default;
			}
		}

		.c-gallery__thumbs .tns-controls {

			[data-controls] {
				right: -#{rem(45px)};

				@include mq(0, desktop) {
					right: 0;
				}

				@include mq(0, 1400px) {
					height: rem(140px);
					margin: -#{rem(70px)} 0 0 0;
				}

				@include mq(1650px) {
					right: -#{rem(75px)};
				}
			}

			[data-controls="prev"] {
				left: -#{rem(45px)};

				@include mq(0, desktop) {
					left: 0;
				}

				@include mq(1650px) {
					left: -#{rem(75px)};
				}
			}
		}
	}
}

.c-gallery__main {

	@include critical {
		position: relative;
		margin: 0 0 rem(10px) 0;

		@include mq(desktop) {
			margin: 0 0 spacing(default) 0;
		}
	}
}

.c-gallery__main__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
	}
}

.c-gallery__main__item {

	@include critical {
		display: block;
		width: 100%;

		&:not(:first-child) {
			display: none;
		}

		.c-gallery--active &:not(:first-child) {
			display: block;
		}
	}
}

.c-gallery__thumbs {

	@include critical {
		overflow-x: scroll;
		position: relative;

		.c-gallery--active & {
			overflow: visible;
		}

		.tns-outer {
			overflow-x: visible;
		}

		@include mq(0, desktop) {

			.js & {
				padding-right: 16.6666666667%;
			}
		}
	}
}

.c-gallery__thumbs__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		width: rem(9999px);
	}
}

.c-gallery__thumbs__item {

	@include critical {
		width: rem(110px);
		display: block;

		@include mq(desktop) {
			width: rem(210px);
		}
	}
}

.c-gallery__thumbs__item__inner {

	@include defer {
		position: relative;
	}
}

.c-gallery__thumbs__link {

	@include critical {
		display: block;
	}
}

.c-gallery__thumbs__button {

	@include critical {
		display: none;
	}

	@include defer {
		display: block;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: color(white);
		border: 0;
		@include appearance-none();
		border-radius: 0;
		opacity: 0.4;
		@include focus();
		transition: opacity 0.25s ease;
		cursor: pointer;

		.c-gallery__thumbs__item--active & {
			opacity: 0;
		}
	}
}

.c-gallery--larger-thumbs {

	.c-gallery__thumbs__item {

		@include critical {
			width: rem(160px);

			@include mq(desktop) {
				width: rem(356.25px);
			}
		}
	}

	.c-gallery__thumbs__item__inner {

		@include critical {
			height: 100%;

			.c-article {
				height: 100%;
			}
		}

		@include defer {

			.js &:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: color(white);
				opacity: 0.4;
				z-index: 1;
			}
		}
	}

	.tns-slide-active {

		.c-gallery__thumbs__item__inner {

			.js &:after {
				display: none;
			}
		}
	}
}
