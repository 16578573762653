.c-image-copy {

	@include critical {
		background: linear-gradient(color(navy) 0%, color(black) 100%);

		@include mq(0, largeDesktop) {
			display: flex;
			flex-wrap: wrap;
		}

		@include mq(largeDesktop) {
			position: relative;
		}

		.c-cms {
			color: color(white);
			font-size: rem(14px);
			line-height: rem(20px);

			@include mq(wide) {
				font-size: rem(18px);
				line-height: rem(28px);
			}

			a {
				color: color(white);
			}
		}
	}

	.c-button:not(.c-button--outline) {

		@include defer {

			@include hover {
				border-color: (white);
			}
		}
	}
}

.c-image-copy__copy-wrapper {

	@include mq(0, largeDesktop) {
		width: 100%;
		order: 2;
	}

	@include mq(largeDesktop) {
		min-height: 600px;
		display: flex;
		align-items: center;

		> * {
			width: 100%;
		}
	}
}

.c-image-copy__copy {

	@include critical {

		@include mq(largeDesktop) {
			padding: 0 0 0 50%;
		}
	}
}

.c-image-copy__copy-inner {

	@include critical {
		padding: 25px 0;

		@include mq(largeDesktop) {
			padding: spacing(large);
		}

		@include mq(1920px) {
			padding: spacing(large) 0 spacing(large) 135px;
		}
	}
}

.c-image-copy__copy-inner-inner {

	@include critical {

		@include mq(1651px) {
			padding: 0 0 0 calc((100vw - 1650px) * 0.5); // For every 1px past 1650px, half a pixel opens up on the end edge of the copy block, this balances the sides
		}

		@include mq(1920px) {
			padding: 0 !important;
		}
	}
}

.c-image-copy__title {

	@include critical {
		margin: 0;
		@include font-bold();
		color: color(yellow);
		font-size: rem(18px);
		line-height: rem(22px);

		@include mq(wide) {
			font-size: rem(36px);
			line-height: rem(47px);
		}
	}
}

.c-image-copy__text {

	@include critical {

		&:not(:first-child) {
			margin: 15px 0 0 0;

			@include mq(largeDesktop) {
				margin: 25px 0 0 0;
			}
		}
	}
}

.c-image-copy__title,
.c-image-copy__text {

	@include critical {
		max-width: 575px;
	}
}

.c-image-copy__buttons {

	@include critical {
		padding: 0 0 5px 0;

		@include mq(largeDesktop) {
			padding: 20px 0;
		}

		.c-button {
			margin: 20px 20px 0 0;
		}
	}
}

.c-image-copy__image-wrapper {

	@include critical {

		@include mq(0, largeDesktop) {
			@include ratio(375, 237);
			position: relative;
			display: block;
			width: 100%;
		}
	}
}

.c-image-copy__image {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include mq(largeDesktop) {
			width: 50%;
		}
	}
}

.c-image-copy--Yellow {

	@include critical {
		background: linear-gradient(#fc0 0%, #c99405 100%);
	}
}

.c-image-copy--White {

	@include critical {
		background: none;
	}
}

.c-image-copy--Yellow,
.c-image-copy--White {

	@include critical {

		.c-cms {
			color: color(black);

			a {
				color: color(black);
			}
		}

		.c-image-copy__title {
			color: color(black);
		}
	}

	.c-button:not(.c-button--outline) {

		@include critical {
			background: color(black);
			color: color(white);
			border-color: color(black);
		}
	}

	.c-button--outline {

		@include critical {
			color: color(black);
			border-color: color(black);
		}

		@include defer {

			@include hover {
				background: color(black);
				color: color(white);
				border-color: color(black);
			}
		}
	}
}

.c-image-copy--White {

	.c-button:not(.c-button--outline) {

		@include defer {

			@include hover {
				background: color(yellow);
				color: color(navy);
				border-color: color(yellow);
			}
		}
	}
}

.c-image-copy--Image-Right {

	@include critical {

		@include mq(largeDesktop) {

			.c-image-copy__image {
				left: auto;
				right: 0;
			}

			.c-image-copy__copy {
				padding: 0 50% 0 0;
			}

			.c-image-copy__copy-inner {

				@include mq(1920px) {
					padding: spacing(large) 135px spacing(large) 0;
				}
			}

			.c-image-copy__copy-inner-inner {

				@include mq(1651px) {
					padding: 0 calc((100vw - 1650px) * 0.5) 0 0;
				}
			}
		}
	}
}
