﻿.c-country-info {

	@include critical {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include mq(wide) {
			margin-top: rem(40px);	
		}
	}

}