﻿.c-no-results {

	@include critical {
		margin: 0;
		padding: rem(30px) 0 0 0;
		text-align: center;
		@include font-bold();
		display: none;

		@include mq(desktop) {
			padding: rem(50px) 0 0 0;
		}
	}
}
