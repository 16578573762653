﻿.c-card {

	@include critical {
		border-top: 1px solid color(lighter-blue);
		border-bottom: 1px solid color(lighter-blue);
		padding: spacing(small) 0;

		@include mq(desktop) {
			padding: spacing(default) 0;
		}
	}
}
