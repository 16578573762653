﻿.c-sso-panel {

	@include critical {
		height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;

		.c-button {
			min-width: 200px;
		}
	}
}

.c-sso-panel__content {

	@include critical {
		@include constrain();
	}
}

.c-sso-panel__title {

	@include critical {
		margin: 0 0 spacing(small) 0;
	}
}

.c-sso-panel__copy {

	@include critical {
		margin: 0 0 spacing(default) 0;
	}
}

.c-sso-panel__actions__action {

	@include critical {

		&:not(:first-child) {
			margin: rem(20px) 0 0 0;
		}
	}
}