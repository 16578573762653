﻿.c-radio-list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.c-radio-list__item {

	@include critical {

		&:not(:last-child) {
			margin: 0 0 spacing(small) 0;
		}
	}
}
